import { DigiEyeCore } from '@/config/http/axios.core-config';
import { DigiEyeUnencrypted } from '@/config/http/axios.unencrypted-config';
import { API_ROUTES_UNENCRYPTED, API_ROUTES_UPDATED } from '@/constants/API_ROUTES';
import { TDateRange, TFieldValues, TReportTemplate } from '@/features/inspection/types';
import { FilterParams, InspectionList, SearchParams, TCreateInspection } from '@/features/inspectionList/types';

export const fetchInspectionList = async (paramsCan: SearchParams): Promise<InspectionList> => {
  const params: SearchParams = {};

  if (paramsCan.page) params.page = paramsCan.page;
  if (paramsCan.limit) params.limit = paramsCan.limit;
  if (paramsCan.referenceNumber) params.referenceNumber = paramsCan.referenceNumber;
  if (paramsCan.vehicleNumber) params.vehicleNumber = paramsCan.vehicleNumber;
  if (paramsCan.assessor) params.assessor = paramsCan.assessor;
  if (paramsCan.assignedTo) params.assignedTo = paramsCan.assignedTo;
  if (paramsCan.inspectionType) params.inspectionType = paramsCan.inspectionType;
  if (paramsCan.inspectionStatus) params.inspectionStatus = paramsCan.inspectionStatus;
  if (paramsCan.startDate) params.startDate = paramsCan.startDate;
  if (paramsCan.endDate) params.endDate = paramsCan.endDate;
  if (paramsCan.makeModel) params.makeModel = paramsCan.makeModel;
  if (paramsCan.eventStatus) params.eventStatus = paramsCan.eventStatus;

  return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_INSPECTION_LIST, { params }).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return data.data.data;
  });
};

export const fetchInspectionCount = async (paramsCan: FilterParams): Promise<number> => {
  const params: FilterParams = {};

  if (paramsCan.referenceNumber) params.referenceNumber = paramsCan.referenceNumber;
  if (paramsCan.vehicleNumber) params.vehicleNumber = paramsCan.vehicleNumber;
  if (paramsCan.assessor) params.assessor = paramsCan.assessor;
  if (paramsCan.assignedTo) params.assignedTo = paramsCan.assignedTo;
  if (paramsCan.inspectionType) params.inspectionType = paramsCan.inspectionType;
  if (paramsCan.inspectionStatus) params.inspectionStatus = paramsCan.inspectionStatus;
  if (paramsCan.startDate) params.startDate = paramsCan.startDate;
  if (paramsCan.endDate) params.endDate = paramsCan.endDate;
  if (paramsCan.makeModel) params.makeModel = paramsCan.makeModel;
  if (paramsCan.eventStatus) params.eventStatus = paramsCan.eventStatus;

  return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_INSPECTION_COUNT, { params }).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);

    return data.data.data.count;
  });
};

export const fetchAssessorList = async () => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_ASSESSOR_LIST).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return [
      { label: 'Customer Link', value: 'customerLink' },
      ...(data?.data?.data || []).map((assessor: { name: string; empId: string }) => ({
        label: assessor.name,
        value: assessor.empId,
      })),
    ];
  });
};

export const fetchInspectionTypes = async () => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_INSPECTION_TYPES).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return data?.data?.data || [];
  });
};

export const fetchAssignedToList = async () => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_ASSIGNED_TO_LIST).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return (data?.data?.data || []).map((assignTo: { name: string; email: string }) => ({
      label: assignTo.name,
      value: assignTo.email,
    }));
  });
};

export const fetchRolesList = async () => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_ROLES_LIST).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return (data?.data?.data || []).map((role: { name: string; code: string }) => ({
      label: role.name,
      value: role.code,
    }));
  });
};

export const createInspection = async ({ form }: TCreateInspection) => {
  try {
    const response = await DigiEyeCore.post(API_ROUTES_UPDATED.CREATE_INSPECTION, form);
    return response?.data || {};
  } catch (error) {
    console.error('Error creating inspection:', error);
    throw error;
  }
};

export const fetchReportTemplates = async (): Promise<TReportTemplate[]> => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.REPORT_TEMPLATES).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return data?.data?.data || [];
  });
};

export const fetchTemplateFields = async (templateName: string) => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.REPORT_TEMPLATE_FIELDS, {
    params: { templateName },
  }).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return data?.data?.data || [];
  });
};

export const fetchFieldValues = async (
  referenceNumber: string,
  templateName: string,
  fieldName?: string
): Promise<TFieldValues[]> => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.REPORT_TEMPLATE_FIELDS_VALUES, {
    params: { referenceNumber, templateName, fieldName, groupBySection: true },
  }).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return data?.data?.data || [];
  });
};

export const createFieldValue = async (
  referenceNumber: string,
  fieldName: string,
  templateName: string,
  value: string
) => {
  return await DigiEyeCore.post(API_ROUTES_UPDATED.REPORT_TEMPLATE_FIELDS_VALUES, {
    referenceNumber,
    fieldName,
    templateName,
    value,
  }).then((data) => {
    if (!data.data.success) throw new Error(data.data.message);
    return data?.data || {};
  });
};

export const fetchEventData = async (referenceNumber: string) => {
  try {
    return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_EVENT_DATA, { params: { referenceNumber } }).then((data) => {
      if (!data.data.success) throw new Error(data.data.message);
      return data?.data || {};
    });
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message);
  }
};

export const fetchInspectionSummary = async ({ uniqueCode }: { uniqueCode: string }) => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.GET_INSPECTION_SUMMARY, { params: { uniqueCode } }).then((data) => {
    if (!data?.data?.success) throw new Error(data?.data?.message || 'Error fetching inspection summary');
    return {
      totalImages: data?.data?.data?.totalImages || 0,
      uploadedImages: data?.data?.data?.uploadedImages || 0,
      totalVideos: data?.data?.data?.totalVideos || 0,
      uploadedVideos: data?.data?.data?.uploadedVideos || 0,
      timestamp: data?.data?.data?.timestamp || Date.now(),
    };
  });
};

export const reportGenerate = async ({
  startDate,
  endDate,
}: TDateRange): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(
    API_ROUTES_UNENCRYPTED.REPORT_GENERATE,
    JSON.stringify({ startDate, endDate })
  ).then((data: any) => {
    return { success: data.data.success, message: data.data.message };
  });
};

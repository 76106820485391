import { ShapeType } from '@/types/imageAnnotator';
import { Radio } from 'antd';
import { Circle, MoveUpRight, Square } from 'lucide-react';
interface ShapeSelectorProps {
  selectedShape: ShapeType;
  onShapeChange: (shape: ShapeType) => void;
}

const ShapeSelector = ({ selectedShape, onShapeChange }: ShapeSelectorProps) => {
  return (
    <Radio.Group
      value={selectedShape}
      onChange={(e) => onShapeChange(e.target.value)}
      optionType="button"
      buttonStyle="solid"
    >
      <Radio.Button value="arrow">
        <MoveUpRight size={12} />
      </Radio.Button>
      <Radio.Button value="rectangle">
        <Square size={12} />
      </Radio.Button>
      <Radio.Button value="circle">
        <Circle size={12} />
      </Radio.Button>
    </Radio.Group>
  );
};

export default ShapeSelector;

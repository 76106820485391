import { TComments, TTags } from '@/features/inspectionList/types';
import { TLocation } from '@/types';
import { TimeLine } from '@inspection/utils/timeLine.util';

export const INSPECTION_VIEW_MODES = ['preview', 'comparison', 'sort'] as const;
export type InspectionViewModes = (typeof INSPECTION_VIEW_MODES)[number];

// TODO: Remove token and tenantId from types
export type InspectionData = {
  accepted: boolean;
  acceptedBy: string;
  accidentDate: string;
  referenceNumber: string;
  vehicleNumber: string;
  inspectionCode: string;
  location: TLocation;
  timeLine: TimeLine;
  inspectionDetails: InspectionDetailsList;
  media: MediaData[];
  policyDetails: PolicyDataList;
  linkToAssessor: boolean;
  portalLink: string;
  submittedDraft: object;
  submitted: boolean;
  blocked: boolean;
  blockedReason: string;
  markedAsBlockedAt: string;
  invalid: boolean;
  invalidReason: string;
  markedAsInvalidAt: string;
  comments: TComments;
};

export type MediaData = {
  thumbnail: string;
  full: string;
  docId: string;
  type: 'image' | 'video';
  capturedAt: string;
  location: TLocation;
  source: string;
  comments: TComments;
  tags: TTags;
  deleted: boolean;
};

export type JobDescription = {
  type: string;
  headers?: string[];
  src?: string;
};

export type KeyValuePair = {
  title: string;
  description: string;
};

export type KeyValuePairList = KeyValuePair[];

export type InspectionDetailsList = {
  inspectionHandlers: { title: string; data: KeyValuePairList };
  inspectionDetails: { title: string; data: KeyValuePairList };
  operatorInputs: { title: string; data: KeyValuePairList };
  assessorInputs: { title: string; data: KeyValuePairList };
};

export type PolicyDataList = {
  policyInformation: { title: string; data: KeyValuePairList };
  intimationDetails: { title: string; data: KeyValuePairList };
};

export type DownloadMediaParams = {
  uniqueCode: string;
};

export type UploadFiles = {
  uniqueCode: string;
  files: File[];
};

export type TCompleteInspection = {
  uniqueCode: string;
  tenantId: string;
  token: string;
  form: object;
};

export type TAjaxInspectionCompletionForm = {
  uniqueCode: string;
  submitStatus: string;
  valuation: string;
  offerStatus: boolean;
  consistency: boolean;
  exceedReport: boolean;
  directSettlement: boolean;
  operatorComment: string;
  mandateGiven: string;
  offerAmount: string;
};

export type TSubmittedDraft = {
  offerStatus: boolean;
  consistency: boolean;
  valuation: string;
  offerAmount: string;
  operatorComment: string;
  submitStatus?: string;
  isCustomerDecisionPending?: boolean;
  collectSalvage?: boolean;
  policeReport?: boolean;
  PAV?: string;
  salvages?: string;
  exceedReport?: boolean;
  directSettlement?: boolean;
  mandateGiven?: string;
};

export type TMarkAsBlocked = {
  uniqueCode: string;
  tenantId: string;
  token: string;
  reason: string;
};

export type TMarkAsResolved = {
  uniqueCode: string;
  tenantId: string;
  token: string;
};

export type TMarkAsInvalid = {
  uniqueCode: string;
  tenantId: string;
  token: string;
  reason: string;
};

export type TMarkAsValid = {
  uniqueCode: string;
  tenantId: string;
  token: string;
};

export type TAddComment = {
  uniqueCode: string;
  docId?: string;
  comment: string;
  docComment: boolean;
  sendNotification?: string;
  notificationRole?: string;
  isPriorityComment?: boolean;
};

export type TDeleteMedia = {
  uniqueCode: string;
  docId: string;
};

export type TPopOver = 'invalid' | 'blocked';

export type TDateRange = {
  startDate: string;
  endDate: string;
};

export type TCommentForm = {
  comment: string;
  isPriorityComment?: boolean;
};

export type TInspectionReportSection = {
  title: string;
  level: 1 | 2;
  description?: string;
  align?: 'left' | 'center' | 'right';
  width?: number;
  type: 'images' | 'paragraph' | 'map' | 'invoice' | 'keyValuePairList' | 'singleImage' | 'jobDescription';
  content?: KeyValuePair[] | JobDescription | any;
  hidden?: boolean;
  showItem?: () => void;
  hideItem?: () => void;
  key: string;
  printMode?: boolean;
};

export type TMediaCardDetails = 'tags' | 'source' | 'createdAt' | 'location' | 'comments';

export type TDetailsFieldType = 'TEXT' | 'NUMBER' | 'DATE' | 'TEXTAREA' | 'CURRENCY';

export type TIncidentDetailsField = {
  id: string;
  fieldName: string;
  fieldLabel: string;
  templateName: string;
  value?: string | number;
  inputType: TDetailsFieldType;
  sections: string[];
};

export type TIncidentDetails = {
  [sectionName: string]: TIncidentDetailsField[];
};

export type TReportTemplate = {
  templateId: string;
  templateName: string;
  templateLabel: string;
  description?: string;
  color?: string;
  fields: TIncidentDetailsField | [];
};
export type TFieldValues = {
  section: string;
  data: { name: string; value: string | number }[];
};

export type GetEvidenceParams = {
  inspectionId?: string | undefined;
  referenceNumber: string | undefined;
};

export type InspectionEvidenceData = {
  inspectionTypeName: string;
  inspectionDate: string;
  evidence: TEvidence[];
};

export type TEvidence = {
  type: 'image' | 'video';
  photoKey: string;
  capturedAt: string;
  source: string;
  doesThumbnailExists: boolean;
  src: string;
  thumbnail: string;
};

export type TEventStatus = {
  inspectionId: string;
  status: string;
};

export type TPointOfImpactResponse = {
  inspectionTypeName: string;
  updatedAt: string;
  pointOfImpact: string;
  inspectionIdOfThePOI: string;
  docId: string;
};

import { AuthContext, TAuthState } from '@/config/AuthContext/useAuth.ts';
import { SEARCH_PARAMS } from '@inspection/constants';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UnauthorizedErrorRedirect } from '../http/unauthorized-error.util';
import { authStateSingleton } from './auth-service.util';

function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useState<TAuthState>({ token: '', tenantId: '' });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const tenantId = searchParams.get(SEARCH_PARAMS.TENANT_ID) || localStorage.getItem('tenantId');
    const token = searchParams.get(SEARCH_PARAMS.TOKEN) || localStorage.getItem('token');

    if (!tenantId || !token) {
      UnauthorizedErrorRedirect();
      return;
    }

    if (tenantId !== localStorage.getItem('tenantId') || token !== localStorage.getItem('token')) {
      localStorage.setItem('tenantId', tenantId);
      localStorage.setItem('token', token);
    }

    const newAuthState = { tenantId, token };
    setAuthState(newAuthState);
    authStateSingleton.authState = newAuthState;

    if (searchParams.get(SEARCH_PARAMS.TOKEN)) {
      searchParams.delete(SEARCH_PARAMS.TOKEN);
      setSearchParams(searchParams);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [searchParams, setSearchParams]);

  return <AuthContext.Provider value={{ authState, setAuthState }}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

import { ShapeType } from '@/types/imageAnnotator';
import { DeleteOutlined, InfoCircleFilled, RedoOutlined, SaveFilled, UndoOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Tooltip } from 'antd';
import Text from 'antd/es/typography/Text';
import { EraserIcon } from 'lucide-react';
import { CSSProperties } from 'react';
import ColorPicker from './ColorPicker';
import ShapeSelector from './ShapeSelector';
import ThicknessControl from './ThicknessControl';

interface ToolbarProps {
  onUndo: () => void;
  onRedo: () => void;
  onClear: () => void;
  onRemoveImage: () => void;
  onDownload: () => void;
  onUpload: () => void;
  onResetAnnotation: () => void;
  onShapeChange: (shape: ShapeType) => void;
  onColorChange: (color: string) => void;
  onThicknessChange: (thickness: number) => void;
  selectedShape: ShapeType;
  selectedColor: string;
  thickness: number;
  canUndo: boolean;
  canRedo: boolean;
  hasImage: boolean;
  isLoading: boolean;
  hasAnnotatedImage: boolean;
  hasAnnotations: boolean;
}

const Toolbar = ({
  onUndo,
  onRedo,
  onClear,
  onShapeChange,
  onColorChange,
  onThicknessChange,
  selectedShape,
  selectedColor,
  thickness,
  canUndo,
  canRedo,
  hasImage,
  onUpload,
  onResetAnnotation,
  hasAnnotatedImage,
  hasAnnotations,
  isLoading = false,
}: ToolbarProps) => {
  const styles: Styles = useStyles();

  return (
    <div style={styles.toolbar}>
      <div style={styles.toolbarMain}>
        <div style={styles.toolbarSection}>
          <ShapeSelector selectedShape={selectedShape} onShapeChange={onShapeChange} />
          <Divider type="vertical" />
          <ColorPicker selectedColor={selectedColor} onColorChange={onColorChange} />
          <Divider type="vertical" />
          <ThicknessControl thickness={thickness} onThicknessChange={onThicknessChange} />
        </div>

        <div style={styles.toolbarSection}>
          <Tooltip title="Undo">
            <Button icon={<UndoOutlined />} onClick={onUndo} disabled={!canUndo} />
          </Tooltip>
          <Tooltip title="Redo">
            <Button icon={<RedoOutlined />} onClick={onRedo} disabled={!canRedo} />
          </Tooltip>
          <Tooltip title="Clear Annotations">
            <Tooltip title="Delete Annotated Image">
              {!canUndo && hasAnnotatedImage ? (
                <Popconfirm
                  title={
                    <p style={{ margin: 0 }}>
                      You are about to clear <span style={{ fontWeight: 700 }}>previously saved annotations</span>. Are
                      you sure you want to continue?
                    </p>
                  }
                  onConfirm={onResetAnnotation}
                  okText="Yes"
                  cancelText="No"
                  overlayStyle={{ maxWidth: '320px' }}
                  placement="bottomLeft"
                >
                  <Button icon={<DeleteOutlined />} disabled={!hasImage} />
                </Popconfirm>
              ) : (
                <Button icon={<EraserIcon size={16} />} onClick={onClear} disabled={!hasImage || !hasAnnotations} />
              )}
            </Tooltip>
          </Tooltip>

          <Tooltip title="Save Annotation">
            <Button
              icon={<SaveFilled />}
              onClick={onUpload}
              disabled={!hasImage || !hasAnnotations}
              type="primary"
              children={'Save'}
              loading={isLoading}
            />
          </Tooltip>
        </div>
      </div>
      {hasAnnotations && (
        <div style={styles.alert}>
          <InfoCircleFilled style={styles.infoColor} />
          <Text children={' Changes are pending. Save before exiting!'} />
        </div>
      )}
    </div>
  );
};

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100px',
    } as CSSProperties,
    toolbarSection: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    } as CSSProperties,
    toolbarTitle: {
      fontSize: '12px',
      margin: 0,
      textAlign: 'left',
      width: '100%',
    } as CSSProperties,
    toolbarMain: { display: 'flex', justifyContent: 'space-between' } as CSSProperties,
    alert: { margin: '2px 6px' } as CSSProperties,
    infoColor: { color: 'orange' } as CSSProperties,
  };
};

export default Toolbar;

import { useAuth } from '@/config/AuthContext/useAuth.ts';
import { fetchInspectionData } from '@inspection/services/inspections.service.ts';
import { useQuery } from '@tanstack/react-query';

export function useInspectionQuery({ uniqueCode }: { uniqueCode: string | undefined }) {
  const {
    authState: { tenantId },
  } = useAuth();

  return useQuery({
    queryKey: ['inspection-data', uniqueCode, tenantId],
    queryFn: () => fetchInspectionData(uniqueCode, tenantId),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
  });
}

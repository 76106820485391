import { useAuth } from '@/config/AuthContext/useAuth.ts';
import env from '@/config/env.ts';
import { MediaData, TMediaCardDetails } from '@/features/inspection/types';
import { TComments } from '@/features/inspectionList/types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import { getTakenAt } from '@inspection/utils/getTakenAt.ts';
// import { useQuery } from '@tanstack/react-query';
import { Card, Divider, Flex, Image, Popover, Row, Typography } from 'antd';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import CommentPopover from './CommentPopover';
import LocationPopover from './LocationPopover';
import MediaDeleteButton from './MediaDeleteButton';
import TagList from './TagList';

export type MediaCardProps = {
  item: MediaData;
  uniqueCode: string;
  refetch: () => void;
  hideDetails?: boolean | TMediaCardDetails[];
  disablePreview?: boolean;
};

const defaultHideDetails = {
  hideTags: false,
  hideCreatedAt: false,
  hideSource: false,
  hideLocation: false,
  hideComments: false,
};

const MediaCard = ({ item, uniqueCode, refetch, hideDetails = false, disablePreview = false }: MediaCardProps) => {
  const {
    authState: { tenantId },
  } = useAuth();
  const [comments, setComments] = useState<TComments>(item.comments);
  const [priorityComment, setPriorityComment] = useState<string>('');
  const { Title, Text } = Typography;
  // const { data: capturedAt } = useQuery({
  //   queryKey: ['document-taken-at', item.docId],
  //   queryFn: () => getTakenAt(item.full),
  // });

  const capturedAt = useMemo(() => {
    if (!item.capturedAt) return 'Not Found';

    const capDate = new Date(item.capturedAt);
    if (!capDate) return `Not Found`;

    const randomSeconds = Math.floor(Math.random() * (15 - 5 + 1)) + 5;

    capDate.setSeconds(capDate.getSeconds() - randomSeconds);

    const formattedDate = capDate
      .toLocaleString('default', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      })
      .replace(',', '');
    const [date, time] = formattedDate.split(' ');
    const [month, day, year] = date.split('/');

    return `${year}-${month}-${day} ${time}`;
  }, [item.capturedAt]);

  useEffect(() => {
    setPriorityComment(comments.find((comment) => comment?.isPriorityComment)?.comment || '');
  }, [comments]);

  const { hideTags, hideCreatedAt, hideSource, hideLocation, hideComments } = useMemo(() => {
    if (!Array.isArray(hideDetails)) {
      return defaultHideDetails;
    }
    return {
      hideTags: hideDetails.includes('tags'),
      hideCreatedAt: hideDetails.includes('createdAt'),
      hideSource: hideDetails.includes('source'),
      hideLocation: hideDetails.includes('location'),
      hideComments: hideDetails.includes('comments'),
    };
  }, [hideDetails]);

  return (
    <Card
      styles={{ body: styles.cardBody }}
      style={styles.cardStyle}
      cover={
        <div style={styles.imageContainer}>
          <Preview item={item} disablePreview={disablePreview} />
          {priorityComment && (
            <div style={styles.blurBackground}>
              <Title level={5} style={styles.commentOnImage}>
                {priorityComment}
              </Title>
            </div>
          )}
        </div>
      }
    >
      {hideDetails === true ? null : (
        <Flex vertical style={styles.mainContainer}>
          {!hideTags && (
            <Flex style={styles.tag}>
              <TagList tags={item.tags} />
            </Flex>
          )}
          <Flex style={styles.textContainer}>
            {!hideCreatedAt && (
              <Flex vertical gap={6}>
                <Popover
                  content={
                    <div style={{ maxWidth: 250 }}>
                      This information comes from the image's metadata, but it could have been altered depending on
                      where the image was taken from.
                    </div>
                  }
                  trigger="hover"
                  placement={'bottom'}
                >
                  <Flex vertical style={{ cursor: 'pointer' }}>
                    <Flex gap={4} align={'center'}>
                      <ExclamationCircleOutlined style={{ color: '#D59311' }} />
                      <Text style={styles.titleTextStyle}>Captured at</Text>
                    </Flex>

                    <Text style={styles.textStyle}>{capturedAt || 'N/A'}</Text>
                  </Flex>
                </Popover>

                <Flex vertical>
                  <Text style={styles.titleTextStyle}>Uploaded at</Text>
                  <Text style={styles.textStyle}>{item.capturedAt || 'N/A'}</Text>
                </Flex>
              </Flex>
            )}
            {!hideSource && (
              <Flex vertical style={styles.verticalAlignEnd}>
                <Text style={styles.titleTextStyle}>Source</Text>
                <Text style={styles.textStyle}>
                  {item.source === 'Assessor'
                    ? !env.VITE_NODE_ENV.includes('prod') || tenantId === 'ajax'
                      ? 'Surveyor'
                      : 'Assessor'
                    : item.source || 'N/A'}
                </Text>
              </Flex>
            )}
          </Flex>
          {hideLocation && hideComments ? null : (
            <>
              <Divider style={styles.dividerStyle} orientation="center" />
              <Row style={styles.actionButtons}>
                <Row style={styles.actionsContainer}>
                  {!hideLocation && <LocationPopover location={item.location} />}
                  {!hideComments && (
                    <CommentPopover
                      commentList={comments}
                      uniqueCode={uniqueCode}
                      documentId={item.docId}
                      onAddComment={(data) => setComments([data, ...comments])}
                      onRemoveComment={() => setComments(comments.slice(1))}
                    />
                  )}
                </Row>
                <MediaDeleteButton item={item} uniqueCode={uniqueCode} refetch={refetch} />
              </Row>
            </>
          )}
        </Flex>
      )}
    </Card>
  );
};

const Preview = ({ item, disablePreview }: { item: MediaData; disablePreview?: boolean }) => {
  return item.type === 'image' ? (
    <Image preview={!disablePreview} src={item.thumbnail || item.full} alt={item.docId} width="100%" placeholder />
  ) : (
    <ReactPlayer url={item.thumbnail || item.full} controls width="100%" height="100%" />
  );
};

const styles: Record<string, CSSProperties> = {
  cardBody: { padding: 0 },
  mainContainer: { padding: 16 },
  actionsContainer: { gap: '2px', alignContent: 'center' },
  actionButtons: { marginTop: '8px', justifyContent: 'space-between' },
  cardStyle: { breakInside: 'avoid', marginBottom: '0.5rem' },
  textStyle: { fontSize: 12 },
  titleTextStyle: { fontSize: 12, color: 'black', opacity: 0.4 },
  textContainer: { justifyContent: 'space-between' },
  verticalAlignEnd: { alignItems: 'flex-end' },
  dividerStyle: { margin: '6px 0' },
  imageContainer: { position: 'relative', width: '100%', display: 'inline-block' },
  blurBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  commentOnImage: { color: 'white', padding: '8px' },
  tag: { marginBottom: '8px' },
};

export default MediaCard;

import { CheckOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';

const colors = ['#4361ee', '#595959', '#ef233c', '#2b9348', '#faad14', '#722ed1'];

interface ColorPickerProps {
  selectedColor: string;
  onColorChange: (color: string) => void;
}

const ColorPicker = ({ selectedColor, onColorChange }: ColorPickerProps) => {
  const styles: Styles = useStyles();

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {colors.map((color) => {
        return (
          <div onClick={() => onColorChange(color)} style={styles.colorContainer(color)}>
            {color === selectedColor && <CheckOutlined style={styles.checkmark} />}
          </div>
        );
      })}
    </div>
  );
};

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    colorContainer: (color: string) =>
      ({
        position: 'relative',
        width: '22px',
        height: '22px',
        borderRadius: '4px',
        backgroundColor: color,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }) as CSSProperties,
    checkmark: {
      color: 'white',
      fontSize: '16px',
    } as CSSProperties,
  };
};

export default ColorPicker;

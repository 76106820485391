import { useAuth } from '@/config/AuthContext/useAuth';
import { FileDoneOutlined } from '@ant-design/icons';
import AjaxCompleteInspection from '@inspection/components/ActionBar/AjaxCompleteinspection';
import CompleteInspection from '@inspection/components/ActionBar/CompleteInspection';
import BlockStatus from '@inspection/components/ActionBar/MarkAsBlocked/BlockStatus';
import InvalidStatus from '@inspection/components/ActionBar/MarkAsInvalid/InvalidStatus';
import MarkAsInvalidOrBlockedPopOverContent from '@inspection/components/ActionBar/MarkAsInvalidOrBlockedPopOverContent';
import { POP_OVER_TYPE } from '@inspection/constants';
import { TPopOver } from '@inspection/types';
import { Dropdown, Flex, MenuProps, Popover, Space, Tooltip } from 'antd';
import { useState } from 'react';

type Props = {
  uniqueCode: string;
  inspectionCode: string;
  sumAssured: number;
  submittedDraft: any;
  refetch: () => void;
  isAuthorizedUser: boolean;
  isInspectionBlocked?: boolean;
  isInspectionCompleted?: boolean;
  isInspectionInvalid: boolean;
};

const ActionsButton = ({
  uniqueCode,
  inspectionCode,
  sumAssured,
  submittedDraft,
  refetch,
  isAuthorizedUser,
  isInspectionBlocked,
  isInspectionCompleted = false,
  isInspectionInvalid = false,
}: Props) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [type, setType] = useState<TPopOver>(POP_OVER_TYPE.INVALID);
  const {
    authState: { tenantId, token },
  } = useAuth();

  const onDropdownClick = (popoverType: TPopOver) => {
    setType(popoverType);
    setShowPopover(true);
  };

  const disabledStatusOfCompleteButton =
    isInspectionInvalid || isInspectionCompleted || isInspectionBlocked || !isAuthorizedUser;

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => onDropdownClick(POP_OVER_TYPE.BLOCKED),
      label: (
        <BlockStatus
          isInspectionCompleted={isInspectionCompleted}
          isAuthorizedUser={isAuthorizedUser}
          uniqueCode={uniqueCode}
          isInspectionBlocked={isInspectionBlocked || false}
          isInspectionInvalid={isInspectionInvalid || false}
          tenantId={tenantId}
          token={token}
          refetch={refetch}
        />
      ),
    },
    ...(tenantId === 'hnb'
      ? [
          {
            key: '2',
            onClick: () => onDropdownClick(POP_OVER_TYPE.INVALID),
            label: (
              <InvalidStatus
                isAuthorizedUser={isAuthorizedUser}
                uniqueCode={uniqueCode}
                isInspectionBlocked={isInspectionBlocked || false}
                isInspectionInvalid={isInspectionInvalid || false}
                tenantId={tenantId}
                token={token}
                refetch={refetch}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Space>
      <Popover
        content={
          <MarkAsInvalidOrBlockedPopOverContent
            type={type}
            refetch={refetch}
            uniqueCode={uniqueCode}
            setShowPopover={setShowPopover}
          />
        }
        title={`Mark this inspection as ${type}`}
        trigger="click"
        open={isAuthorizedUser && !isInspectionBlocked && !isInspectionInvalid && showPopover}
      >
        <Dropdown.Button
          menu={{ items }}
          trigger={['click']}
          onOpenChange={(flag) => flag && setShowPopover(false)}
          className={disabledStatusOfCompleteButton ? 'disable-dropdown-default-btn' : ''}
        >
          {tenantId === 'ajax' ? (
            <AjaxCompleteInspection
              uniqueCode={uniqueCode}
              sumAssured={sumAssured}
              submittedDraft={submittedDraft}
              refetch={refetch}
              disable={disabledStatusOfCompleteButton}
            >
              <Tooltip
                placement="bottom"
                title={
                  !isAuthorizedUser
                    ? "You don't have permission to complete"
                    : isInspectionBlocked
                      ? 'Inspection Blocked'
                      : isInspectionCompleted
                        ? 'Already Completed'
                        : 'Mark this inspection as complete'
                }
                mouseEnterDelay={2}
              >
                <Flex gap={'small'}>
                  <FileDoneOutlined />
                  Complete
                </Flex>
              </Tooltip>
            </AjaxCompleteInspection>
          ) : (
            <CompleteInspection
              uniqueCode={uniqueCode}
              inspectionCode={inspectionCode}
              sumAssured={sumAssured}
              submittedDraft={submittedDraft}
              refetch={refetch}
              disable={disabledStatusOfCompleteButton}
            >
              <Tooltip
                placement="bottom"
                title={
                  !isAuthorizedUser
                    ? "You don't have permission to complete"
                    : isInspectionBlocked
                      ? 'Inspection Blocked'
                      : isInspectionCompleted
                        ? 'Already Completed'
                        : 'Mark this inspection as complete'
                }
                mouseEnterDelay={2}
              >
                <Flex gap={'small'}>
                  <FileDoneOutlined />
                  Complete
                </Flex>
              </Tooltip>
            </CompleteInspection>
          )}
        </Dropdown.Button>
      </Popover>
    </Space>
  );
};

export default ActionsButton;

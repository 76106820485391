import { InputNumber, InputNumberProps, Select } from 'antd';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';

import { CSSProperties, useState } from 'react';

type TelephoneInputProps = InputNumberProps & {
  onCountryChange?: (code: string) => void;
  width?: string;
  defaultCountry?: string;
};

const TelephoneSelectInput = ({
  onCountryChange = (code) => {
    return code;
  },
  width = '100%',
  defaultCountry = 'LK',
  ...props
}: TelephoneInputProps) => {
  const [countryCode, setCountryCode] = useState<string>(getCountryCallingCode(defaultCountry as CountryCode));
  const [internalValue, setInternalValue] = useState<string>('');
  const styles = useStyles(width);
  const countries = getCountries();

  const countryOptions = countries.map((country, index) => {
    return {
      key: index + 1,
      value: getCountryCallingCode(country),
      country: country,
      label: `${country}(+${getCountryCallingCode(country)})`,
    };
  });

  const selectBefore = (
    <Select
      style={{ width: 100 }}
      defaultValue={getCountryCallingCode(defaultCountry as CountryCode)}
      onChange={(value) => {
        setInternalValue('');
        onCountryChange(value);
        setCountryCode(value);
      }}
      showSearch
      optionFilterProp="label"
      options={countryOptions}
    />
  );

  const handleChange = (value: string | number | null) => {
    if (value === null || value === undefined) {
      setInternalValue('');
    } else if (typeof value === 'string' || typeof value === 'number') {
      const nationalNumber = value.toString();
      const fullNumber = `${countryCode}${nationalNumber}`;

      setInternalValue(fullNumber);

      if (props.onChange) {
        props.onChange(fullNumber);
      }
    }
  };

  return (
    <InputNumber
      {...props}
      value={internalValue.replace(`${countryCode}`, '')}
      addonBefore={selectBefore}
      style={styles.telephone}
      type="text"
      itemType="text"
      formatter={(value) => {
        const country = countryOptions.find((country) => country.value === countryCode)?.country;
        if (!country) return `${value}`;
        if (isValidPhoneNumber(`${value}`, country)) return parsePhoneNumber(`${value}`, country).formatNational();
        return `${value}`;
      }}
      onChange={handleChange}
      onKeyDown={(event) => {
        const { key } = event;

        if (
          key === 'Backspace' ||
          key === 'Delete' ||
          key === 'Tab' ||
          key === 'Escape' ||
          key === 'Enter' ||
          key === 'ArrowLeft' ||
          key === 'ArrowRight'
        ) {
          return;
        }

        if ((event.ctrlKey || event.metaKey) && key.toLowerCase() === 'v') {
          return;
        }

        const regex = /^[0-9]*$/;
        if (!regex.test(key)) {
          event.preventDefault();
        }
      }}
      precision={0}
      controls={false}
    />
  );
};

export default TelephoneSelectInput;

const useStyles = (width: string) => {
  return {
    telephone: {
      width: width,
    } as CSSProperties,
  };
};

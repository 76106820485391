import Home from '@/features/home';
import Inspection from '@/features/inspection';
import InspectionViewLayout from '@/features/inspection/layouts/InspectionViewLayout';
import { InspectionList } from '@/features/inspectionList';
import InspectionReportsLayout from '@inspectionReport/layouts/InspectionReportsLayout';
import InspectionInvoiceLayout from '@invoices/layouts/InspectionInvoiceLayout';
import { Button, Layout, Result } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { CSSProperties } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { Nav } from './features/nav';

function App() {
  const styles = useStyles();

  return (
    <Layout style={styles.body}>
      <Content style={styles.mainContainer}>
        <Routes>
          <Route path="/" element={<Nav />}>
            <Route index element={<Home />} />
            <Route path="inspection/" element={<Inspection />}>
              <Route index element={<InspectionList />} />
              {/* TODO: Remove this with dashboard link update */}
              <Route path="list" element={<InspectionList />} />
              <Route path="new" element={<InspectionList />} />
              <Route path=":uniqueCode" element={<InspectionViewLayout />} />
              <Route path="report/:uniqueCode" element={<InspectionReportsLayout />} />
              <Route path="invoice/:uniqueCode" element={<InspectionInvoiceLayout />} />
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link to={`/`}>
                    <Button type="primary">Back Home</Button>
                  </Link>
                }
              />
            }
          />
        </Routes>
      </Content>
    </Layout>
  );
}

export default App;

const useStyles = () => {
  return {
    body: {
      backgroundColor: 'white',
    } as CSSProperties,
    mainContainer: {
      backgroundColor: '#F5F4F7',
      minHeight: 'calc(100vh - 60px)',
    } as CSSProperties,
  };
};

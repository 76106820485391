import StatisticGrid from '@/components/StatisticGrid';
import { useAuth } from '@/config/AuthContext/useAuth.ts';
import { getStatusTagColorAndText } from '@/features/inspectionList/utils/statusColor.util';
import InspectionHeaderActions from '@inspection/components/InspectionHeaderActions';
import { fetchInspectionData } from '@inspection/services/inspections.service';
import { KeyValuePairList } from '@inspection/types';
import { useQuery } from '@tanstack/react-query';
import { Col, Divider, Flex, Row, Space, Statistic, Tag } from 'antd';

type Props = {
  referenceNumber: string;
  inspectionType: string;
  accidentDate: string;
  uniqueCode: string;
};

export const InspectionHeader = ({ referenceNumber, inspectionType, accidentDate, uniqueCode }: Props) => {
  const {
    authState: { tenantId },
  } = useAuth();

  const {
    data: inspection,
    isLoading,
    isRefetching: isInspectionDataRefetching,
    refetch: refetchInspectionData,
  } = useQuery({
    queryKey: ['inspection-data', uniqueCode],
    queryFn: () => fetchInspectionData(uniqueCode, tenantId),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
  });
  const styles = useStyles();

  return (
    <>
      <Flex gap={'1rem'} align="flex-start" justify="space-between" style={{ paddingLeft: '8px' }}>
        <Flex align="flex-start">
          <Col>
            <Row>
              <Statistic style={{ fontWeight: 'bold' }} value={referenceNumber} />
              <Divider type="vertical" style={styles.divider} />
              <Statistic style={{ fontWeight: 'bold' }} value={inspectionType} />
              <Divider type="vertical" style={styles.divider} />
            </Row>
            <Tag
              style={{ marginTop: '8px', marginBottom: '8px' }}
              color={inspection ? getStatusTagColorAndText(inspection).color : 'default'}
            >
              {inspection ? getStatusTagColorAndText(inspection).tag : ''}
            </Tag>
          </Col>
        </Flex>
        <Space style={{ textAlign: 'left' }}>
          <InspectionHeaderActions
            isBlocked={inspection?.blocked || false}
            refetch={refetchInspectionData}
            uniqueCode={uniqueCode}
            inspection={inspection as any}
          />
        </Space>
      </Flex>
      <Col style={{ marginLeft: '8px' }}>
        <StatisticGrid
          data={inspection?.inspectionDetails?.inspectionHandlers?.data as KeyValuePairList}
          title="Handlers"
          showDivider
          columns={2}
          isLoading={isLoading || isInspectionDataRefetching}
        />
        <StatisticGrid
          data={inspection?.inspectionDetails?.operatorInputs?.data as KeyValuePairList}
          title="Operator inputs"
          showDivider
          columns={3}
          defaultExpandedItems={3}
          isLoading={isLoading || isInspectionDataRefetching}
          more
        />
      </Col>
      <Statistic style={{ marginLeft: '8px', marginBottom: '4px' }} value={accidentDate.split(' ')[0]} />
    </>
  );
};

const useStyles = () => {
  return {
    divider: {
      backgroundColor: '#d9d9d9',
      marginTop: '4px',
    },
  } as const;
};

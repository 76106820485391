import { TCustomFooterForm } from '@invoices/util/types';
import { Button, Col, DatePicker, Flex, Form, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useState } from 'react';

type CustomInvoiceFooterProps = {
  setFooterData: (data: TCustomFooterForm) => void;
  loading: boolean;
  customerNote?: string;
  terms?: string;
  dueDate?: string;
};

const CustomFooterForm = ({ loading, setFooterData, customerNote, terms, dueDate }: CustomInvoiceFooterProps) => {
  const [form] = Form.useForm();
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [isEditingTerms, setIsEditingTerms] = useState(false);

  const initialValues = {
    terms: terms ?? `Payment is due within 30 day(s) from the date of the invoice.`,
    timePeriod: dueDate ? null : 30,
    dueDate: dueDate ? dayjs(dueDate) : dayjs().add(30, 'day'),
    customerNote: customerNote,
  };

  const onValuesChange = () => {
    const timePeriod = form.getFieldValue('timePeriod');
    const dueDate = form.getFieldValue('dueDate');

    if (isEditingTerms) return;

    setIsCustomDate(timePeriod === null);

    if (!timePeriod) {
      form.setFieldValue(
        'terms',
        `Payment is due within ${dayjs(dueDate)?.endOf('day').diff(dayjs(), 'day') || ''} day(s) from the date of the invoice.`
      );
      return;
    }

    const newDueDate = dayjs().add(timePeriod, 'day');
    if (!newDueDate.isSame(dueDate)) form.setFieldValue('dueDate', newDueDate);

    const newTerms = `Payment is due within ${timePeriod} day(s) from the date of the invoice.`;
    form.setFieldValue('terms', newTerms);
  };

  const onFinish = (values: TCustomFooterForm) => {
    setFooterData({
      customerNote: values.customerNote,
      terms: values.terms,
      dueDate: values.dueDate,
    });
  };

  return (
    <Form
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      form={form}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: '100%' }}
    >
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label="Time Period" name="timePeriod">
            <Select
              options={[
                { label: 'Custom Date', value: null },
                { label: 'Due by 30 day', value: 30 },
                { label: 'Due by 60 day', value: 60 },
                { label: 'Due by 90 day', value: 90 },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Due Date" name="dueDate">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs()}
              style={{ width: '100%' }}
              minDate={dayjs()}
              disabled={!isCustomDate}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Terms" name="terms">
        <TextArea
          placeholder="Terms"
          rows={3}
          onFocus={() => setIsEditingTerms(true)}
          onBlur={() => setIsEditingTerms(false)}
        />
      </Form.Item>

      <Form.Item label="Customer Note" name="customerNote">
        <TextArea placeholder="Customer Note" rows={3} />
      </Form.Item>

      <Form.Item>
        <Flex gap={8} justify="flex-end">
          <Button type="default" htmlType="reset">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
export default CustomFooterForm;

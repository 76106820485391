export const APPLICABLE_TYPES = {
  PAV: ['OTS', 'OFS', 'OTSR', 'GE', 'VGI', 'DUP'],
  PoliceReport: ['OTS', 'OFS', 'OTSR'],
  CDP: ['OTS', 'OFS'],
};

export const SUBMIT_STATUSES = {
  COMPLETED: 'completed',
  ASSESSOR_INSPECTION_REQUIRED: 'assessor_inspection_required',
  GARAGE_INSPECTION_REQUIRED: 'garage_inspection_required',
  CUSTOMER_DECISION_PENDING: 'customer_decision_pending',
};

export const INSPECTION_STATUSES = {
  CREATED: 'created',
  REMOTE_USER_CONNECTED: 'remote_user_connected',
  OPERATOR_CONNECTED: 'operator_connected',
  DOCUMENTS_COLLECTED: 'documents_collected',
  ASSESSOR_INSPECTION_REQUIRED: 'assessor_inspection_required',
  GARAGE_INSPECTION_REQUIRED: 'garage_inspection_required',
  CUSTOMER_DECISION_PENDING: 'customer_decision_pending',
  COMPLETED: 'completed',
  CUSTOMER_AGREEMENT_SIGNED: 'customer_agreement_signed',
  LAPSED: 'lapsed',
};

export const SWITCH_LABELS = {
  YES: 'Yes',
  NO: 'No',
  CDP: 'CDP',
};

export const SEARCH_PARAMS = {
  UNIQUE_CODE: 'uniqueCode',
  TENANT_ID: 'tenantId',
  TOKEN: 'token',
} as const;

export const POP_OVER_TYPE = {
  INVALID: 'invalid',
  BLOCKED: 'blocked',
} as const;

export const MEDIA_TAGS = {
  GALLERY: 'gallery',
  CAMERA: 'camera',
};

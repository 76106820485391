import ContentOverlay from '@/components/ContentOverlay';
import StatisticGrid from '@/components/StatisticGrid';
import { useAuth } from '@/config/AuthContext/useAuth.ts';
import env from '@/config/env.ts';
import { queryClient } from '@/config/http/react-query.config.ts';
import { useToastApi } from '@/hooks/useToastApi.tsx';
import { CommentOutlined, FileTextOutlined } from '@ant-design/icons';
import {
  getCurrentEventStatuses,
  getEventStatuses,
  updateEventStatus,
} from '@inspection/services/inspections.service.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Divider, Flex, Select, Tag, Typography } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { KeyValuePairList, TEventStatus } from '../types';

type Props = {
  vehicleNumber: string;
  inspectionStatus: string;
  accidentDate: string;
  inspectionId: string;
  policyInformation: { title: string; data: KeyValuePairList };
  intimationDetails: { title: string; data: KeyValuePairList };
};

const InspectionSummery = (props: Props) => {
  const styles: Styles = useStyles();
  const {
    authState: { tenantId },
  } = useAuth();

  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [showIntimationDetails, setShowIntimationDetails] = useState(false);
  const [overlayTitle, setOverlayTitle] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const { data: eventStatuses } = useQuery({
    queryKey: ['event-statuses'],
    queryFn: () => getEventStatuses(),
  });

  const { data: currentEventStatus } = useQuery({
    queryKey: ['current-event-status'],
    queryFn: () => getCurrentEventStatuses(props.inspectionId),
  });

  useEffect(() => {
    if (currentEventStatus?.eventStatus) {
      setSelectedStatus(currentEventStatus.eventStatus);
    }
  }, [currentEventStatus]);

  const toastApi = useToastApi();
  const mutation = useMutation({
    mutationFn: ({ inspectionId, status }: TEventStatus) => updateEventStatus({ inspectionId, status }),
    onSuccess: (data) => {
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? 'Event status updated successfully!' : data.message,
        duration: 2,
      });
      if (data?.success) {
        queryClient.invalidateQueries({ queryKey: ['event-statuses'] });
        queryClient.invalidateQueries({ queryKey: ['current-event-status'] });
      }
    },
    onError: (data) => {
      toastApi.open({
        type: 'error',
        content: data?.message || 'Failed to update the event status!',
        duration: 2,
      });
    },
  });

  return (
    <Card
      size="small"
      style={{
        marginBottom: '16px',
      }}
      styles={{ body: { height: '100%' } }}
    >
      <Flex vertical justify="space-between" style={{ height: '100%' }}>
        <Flex justify="space-between" align="start">
          <div>
            <Typography.Title level={3} style={styles.vehicleNumberPlate}>
              {props.vehicleNumber}
            </Typography.Title>
          </div>
          <Flex vertical align="end">
            <Tag style={styles.statusTag} color="blue">
              {props.inspectionStatus}
            </Tag>
            <Typography.Paragraph style={{ margin: 0, fontSize: 12, textAlign: 'end' }}>
              {props.accidentDate}
            </Typography.Paragraph>
          </Flex>
        </Flex>
        <Divider style={{ margin: 0 }} />
        {!env.VITE_NODE_ENV.includes('prod') || tenantId === 'ajax' ? (
          <Flex style={{ marginTop: '10px' }}>
            {eventStatuses && (
              <Flex vertical style={{ width: '100%' }}>
                <Flex vertical={false} justify={'space-between'} style={{ marginBottom: '4px' }}>
                  <Typography.Text style={styles.statusTagText}>Event Status</Typography.Text>
                </Flex>
                <Select
                  size={'small'}
                  value={selectedStatus}
                  style={{ width: '100%' }}
                  popupMatchSelectWidth={true}
                  onChange={(statusName: string) => {
                    setSelectedStatus(statusName);
                    mutation.mutate({ inspectionId: props.inspectionId, status: statusName });
                  }}
                >
                  {eventStatuses?.map(({ statusDescription }) => (
                    <Select.Option key={statusDescription} value={statusDescription}>
                      {statusDescription}
                    </Select.Option>
                  ))}
                </Select>
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex justify="space-between" gap={8}>
            <Button
              style={{ width: '50%' }}
              onClick={() => {
                setOverlayTitle('Policy Details');
                setShowPolicyDetails(true);
              }}
            >
              <FileTextOutlined />
              Policy Details
            </Button>
            <Button
              style={{ width: '50%' }}
              onClick={() => {
                setOverlayTitle('Intimation Details');
                setShowIntimationDetails(true);
              }}
            >
              <CommentOutlined />
              Intimation Details
            </Button>
          </Flex>
        )}
      </Flex>
      <ContentOverlay
        title={
          props.policyInformation
            ? props.policyInformation.title
              ? props.policyInformation.title
              : overlayTitle
            : overlayTitle
        }
        open={showPolicyDetails}
        onCancel={() => setShowPolicyDetails(false)}
      >
        {props.policyInformation ? (
          props.policyInformation.data.length > 0 ? (
            <StatisticGrid data={props.policyInformation.data} columns={4} />
          ) : (
            <Typography.Text>No Data Found</Typography.Text>
          )
        ) : (
          <Typography.Text>No Data Found</Typography.Text>
        )}
      </ContentOverlay>
      <ContentOverlay
        title={
          props.intimationDetails
            ? props.intimationDetails.title
              ? props.intimationDetails.title
              : overlayTitle
            : overlayTitle
        }
        open={showIntimationDetails}
        onCancel={() => setShowIntimationDetails(false)}
      >
        {props.intimationDetails ? (
          props.intimationDetails.data.length > 0 ? (
            <StatisticGrid data={props.intimationDetails.data} columns={4} />
          ) : (
            <Typography.Text>No Data Found</Typography.Text>
          )
        ) : (
          <Typography.Text>No Data Found</Typography.Text>
        )}
      </ContentOverlay>
    </Card>
  );
};

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    statusTag: {
      width: 'fit-content',
      height: 'fit-content',
      fontWeight: 600,
      fontSize: '10px',
      textWrap: 'wrap',
      lineHeight: '1rem',
      margin: 0,
      overflowWrap: 'break-word',
      maxWidth: '200px',
    } as CSSProperties,
    vehicleNumberPlate: {
      margin: 0,
      fontSize: '16px',
      fontFamily: 'monospace',
      border: '1px lightgray solid',
      borderRadius: '4px',
      padding: '2px 8px',
    } as CSSProperties,
    statusTagText: { color: '#8695aa' },
  };
};

export default InspectionSummery;

import CurrencySelectInput from '@/components/CurrencySelectInput';
import { queryClient } from '@/config/http/react-query.config';
import {
  createFieldValue,
  fetchFieldValues,
  fetchTemplateFields,
} from '@/features/inspectionList/services/inspectionList.service';
import { TIncidentDetails, TIncidentDetailsField } from '@inspection/types';
import { combineFieldsWithValues } from '@inspection/utils/dataTransform.util';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Divider, Empty, Flex, Form, Input, Row, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { CSSProperties, useMemo } from 'react';

type IncidentDetailsFormProps = {
  templateName: string;
  referenceNumber: string;
  view?: 'MODAL' | 'PAGE';
};

const IncidentDetailsForm = ({ referenceNumber, view = 'PAGE', templateName }: IncidentDetailsFormProps) => {
  const [form] = Form.useForm();
  const styles = useStyles();

  const { data: templateFields, isLoading: templatefieldsIsLoading } = useQuery({
    queryKey: ['template-fields', templateName],
    queryFn: () => fetchTemplateFields(templateName),
    refetchOnMount: true,
  });

  const { data: fieldValues } = useQuery({
    queryKey: ['fields-values', referenceNumber, templateName],
    queryFn: () => fetchFieldValues(referenceNumber, templateName),
    refetchOnMount: true,
  });

  const mutation = useMutation({
    mutationFn: ({ value, fieldName }: { value: any; fieldName: string }) =>
      createFieldValue(referenceNumber, fieldName, templateName, value),
    onSuccess: (_, { fieldName }) => {
      queryClient.invalidateQueries({ queryKey: ['fields-values', referenceNumber] });
      queryClient.invalidateQueries({ queryKey: ['template-fields', templateName] });
      form.setFields([
        {
          name: fieldName,
          errors: [],
          validated: true,
          validating: false,
        },
      ]);
    },
    onError: (error, { fieldName }) => {
      if (error instanceof Error) {
        form.setFields([
          {
            name: fieldName,
            errors: ['Error while saving this field.'],
            validated: false,
            validating: false,
          },
        ]);
      }
    },
  });

  const mergedData: TIncidentDetails | null = useMemo(() => {
    if (!templateFields) {
      return null;
    }
    const initialValues: Record<string, string | number> = {};
    fieldValues?.forEach((section) => {
      section.data.forEach((item) => {
        initialValues[item.name] = item.value;
      });
    });
    form.setFieldsValue(initialValues);
    return combineFieldsWithValues(templateFields, fieldValues ?? []);
  }, [templateFields, fieldValues, templateName, referenceNumber]);

  if (templatefieldsIsLoading) {
    return (
      <Flex style={styles.spinner} vertical>
        <Spin size={view === 'PAGE' ? 'large' : 'default'} />
        {view === 'MODAL' && <Text style={styles.spinnerText}>Loading report-specific fields. Please wait.</Text>}
      </Flex>
    );
  }

  if (!mergedData) {
    return (
      <Empty
        description={
          view === 'PAGE'
            ? 'No Incident Overview Details'
            : 'Specific field(s) for this template is/are not available, you can download the report.'
        }
      />
    );
  }

  const onValuesChange = (values: any) => {
    const changeKey = Object.keys(values)[0];
    form.setFields([
      {
        name: changeKey,
        errors: [],
      },
    ]);
  };

  const renderFields = (sectionsMap: TIncidentDetails) => {
    const handleBlur = (
      event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: TIncidentDetailsField
    ) => {
      const newValue = event.target.value;
      if (newValue !== field.value) {
        mutation.mutate({
          value: newValue,
          fieldName: field.fieldName,
        });
      }
    };
    return Object.entries(sectionsMap)?.map(([section, fields]) => (
      <div key={section}>
        {view !== 'MODAL' && (
          <Divider style={styles.divider} dashed orientation="left" orientationMargin="0">
            <Title style={styles.modalTitle} level={5}>
              {section}
            </Title>
          </Divider>
        )}
        <Row gutter={view === 'MODAL' ? 4 : [16, 0]} style={styles.formContainer} align="bottom">
          {(fields as TIncidentDetailsField[]).map((field: TIncidentDetailsField) => {
            return (
              <Col
                key={field.fieldName}
                xs={24}
                lg={(fields as TIncidentDetailsField[])?.length === 1 || view === 'MODAL' ? 24 : 12}
                xl={(fields as TIncidentDetailsField[])?.length === 1 || view === 'MODAL' ? 24 : 8}
              >
                <Form.Item name={field.fieldName} label={field.fieldLabel} hasFeedback>
                  {field.inputType === 'TEXT' && <Input onBlur={(event) => handleBlur(event, field)} />}
                  {field.inputType === 'NUMBER' && <Input type="number" onBlur={(event) => handleBlur(event, field)} />}
                  {field.inputType === 'DATE' && (
                    //TODO: Fix DatePicker issue
                    // <DatePicker
                    //   value={field.value ? dayjs(field.value).format('YYYY-MM-DD') : undefined}
                    //   onChange={(_, dateString) => {
                    //     if (!isEmpty(dateString)) {
                    //       mutation.mutate({
                    //         value: dateString,
                    //         fieldName: field.fieldName,
                    //         actionType: field.value ? 'UPDATE' : 'CREATE',
                    //       });
                    //     }
                    //   }}
                    // />
                    <Input
                      type="date"
                      onBlur={(event) => handleBlur(event, field)}
                      value={field.value ? dayjs(field.value).format('YYYY-MM-DD') : undefined}
                    />
                  )}
                  {field.inputType === 'CURRENCY' && (
                    <CurrencySelectInput onBlur={(event) => handleBlur(event, field)} disableCurrencySelect />
                  )}
                  {field.inputType === 'TEXTAREA' && <TextArea rows={4} onBlur={(event) => handleBlur(event, field)} />}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </div>
    ));
  };

  return (
    <Form form={form} layout="vertical" onValuesChange={onValuesChange}>
      {mergedData && renderFields(mergedData as TIncidentDetails)}
    </Form>
  );
};

const useStyles = () => {
  return {
    modalTitle: { margin: 0 } as CSSProperties,
    divider: { borderColor: '#0035cc', margin: '32px 0' } as CSSProperties,
    formContainer: { marginTop: 16 } as CSSProperties,
    spinner: { justifyContent: 'center', padding: 24, justifyItems: 'center' } as CSSProperties,
    spinnerText: { textAlign: 'center', marginTop: 8 } as CSSProperties,
  };
};

export default IncidentDetailsForm;

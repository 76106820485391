import { Segmented } from 'antd';
import { TCustomInvoiceSegments } from '../util/types';

type CustomInvoiceSegmentsProps = {
  segment: TCustomInvoiceSegments;
  setSegments: (value: TCustomInvoiceSegments) => void;
  hidden?: boolean;
};

const CustomInvoiceSegments = ({ setSegments, segment, hidden = false }: CustomInvoiceSegmentsProps) => {
  return (
    <Segmented<TCustomInvoiceSegments>
      options={hidden ? [] : ['Header', 'Line Items', 'Footer']}
      value={segment}
      onChange={(value) => {
        setSegments(value);
      }}
    />
  );
};
export default CustomInvoiceSegments;

import { z } from 'zod';

const envSchema = z.object({
  VITE_NODE_ENV: z.string({ message: 'VITE_NODE_ENV is required' }),
  VITE_API_URL: z.string({ message: 'VITE_API_URL is required' }),
  VITE_CORE_URL: z.string({ message: 'VITE_CORE_URL is required' }),
  VITE_UNENCRYPTED_API_URL: z.string({ message: 'VITE_UNENCRYPTED_API_URL is required' }),
  VITE_DASHBOARD_URL: z.string({ message: 'VITE_DASHBOARD_URL is required' }),
  VITE_GOOGLE_MAP_API_KEY: z.string({ message: 'VITE_GOOGLE_MAP_API_KEY is required' }),
  VITE_MASKING_KEY: z.string({ message: 'VITE_MASKING_KEY is required' }),
  VITE_GOOGLE_MAP_ZOOM_LEVEL: z.preprocess(
    (val) => Number(val),
    z.number({ message: 'VITE_GOOGLE_MAP_ZOOM_LEVEL is required' })
  ),
  // z.string({ message: 'VITE_GOOGLE_MAP_ZOOM_LEVEL is required' }),
  VITE_GENERAL_INSPECTION_PORTAL: z.string({ message: 'VITE_GENERAL_INSPECTION_PORTAL is required' }),
  VITE_MOTOR_INSPECTION_PORTAL: z.string({ message: 'VITE_MOTOR_INSPECTION_PORTAL is required' }),
  VITE_GOOGLE_MAP_LINK: z.string({ message: 'VITE_GOGGLE_MAP_LINK should be a valid URL' }).optional(),
  VITE_SENTRY_DSN: z.string({ message: 'VITE_SENTRY_DSN is required' }),
  VITE_TRACES_SAMPLE_RATE: z.preprocess(
    (val) => Number(val),
    z.number({ message: 'TRACES_SAMPLE_RATE is required' }).default(0.5)
  ),
  VITE_PROFILES_SAMPLE_RATE: z.preprocess(
    (val) => Number(val),
    z.number({ message: 'PROFILES_SAMPLE_RATE is required' }).default(0.5)
  ),
  VITE_REPLAYS_SESSION_SAMPLE_RATE: z.preprocess(
    (val) => Number(val),
    z.number({ message: 'REPLAYS_SESSION_SAMPLE_RATE is required' }).default(0.1)
  ),
  VITE_REPLAYS_ON_ERROR_SAMPLE_RATE: z.preprocess(
    (val) => Number(val),
    z.number({ message: 'REPLAYS_ON_ERROR_SAMPLE_RATE is required' }).default(0.5)
  ),
  VITE_ENABLE_SENTRY: z.preprocess((val) => val === 'true', z.boolean()).default(false),

  VITE_CURRENCY_SETTINGS: z.string({ message: 'VITE_CURRENCY_SETTINGS is required' }).transform((str) => {
    try {
      const parsed = JSON.parse(str);
      return z.record(z.string()).parse(parsed); 
    } catch (e) {
      throw new Error('Invalid VITE_CURRENCY_SETTINGS JSON format');
    }
  }),
  VITE_STATS_DASHBOARD_URL: z.string({ message: 'VITE_STATS_DASHBOARD_URL is required' }),
});

type Env = z.infer<typeof envSchema>;

const parseEnv = (): Env => {
  try {
    return envSchema.parse(import.meta.env);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error('Environment variable validation error:', error.errors);
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const env: Env = parseEnv();

export default env;

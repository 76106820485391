import type { Annotation } from '@/types/imageAnnotator';
import { drawArrow, drawCircle, drawRectangle } from '@/utils/canvas.util';
import React, { CSSProperties, useEffect, useRef } from 'react';

interface CanvasProps {
  image: HTMLImageElement | null;
  annotations: Annotation[];
  isDrawing: boolean;
  currentAnnotation: Annotation | null;
  onMouseDown: (e: React.MouseEvent) => void;
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseUp: () => void;
}

const Canvas = ({
  image,
  annotations,
  isDrawing,
  currentAnnotation,
  onMouseDown,
  onMouseMove,
  onMouseUp,
}: CanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const styles: Styles = useStyles();

  const getMousePosition = (e: React.MouseEvent) => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  const drawShape = (ctx: CanvasRenderingContext2D, annotation: Annotation) => {
    const { type, startX, startY, endX, endY, color, thickness } = annotation;
    switch (type) {
      case 'arrow':
        drawArrow(ctx, startX, startY, endX, endY, color, thickness);
        break;
      case 'rectangle':
        drawRectangle(ctx, startX, startY, endX, endY, color, thickness);
        break;
      case 'circle':
        drawCircle(ctx, startX, startY, endX, endY, color, thickness);
        break;
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx || !container || !image) return;

    // Set canvas size to match image dimensions with a maximum size
    const maxWidth = Math.min(container.clientWidth - 40, image.width);
    const scale = maxWidth / image.width;
    const width = image.width * scale;
    const height = image.height * scale;

    // Set canvas dimensions to match image dimensions for proper rendering
    canvas.width = image.width;
    canvas.height = image.height;

    // Set display size
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    // Clear and draw image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    // Draw annotations
    annotations.forEach((annotation) => {
      drawShape(ctx, annotation);
    });

    if (isDrawing && currentAnnotation) {
      drawShape(ctx, currentAnnotation);
    }
  }, [image, annotations, isDrawing, currentAnnotation]);

  const handleMouseDown = (e: React.MouseEvent) => {
    const pos = getMousePosition(e);
    const customEvent = {
      ...e,
      clientX: pos.x,
      clientY: pos.y,
    } as React.MouseEvent;
    onMouseDown(customEvent);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const pos = getMousePosition(e);
    const customEvent = {
      ...e,
      clientX: pos.x,
      clientY: pos.y,
    } as React.MouseEvent;
    onMouseMove(customEvent);
  };

  return (
    <div ref={containerRef} style={styles.canvasContainer}>
      <canvas
        ref={canvasRef}
        style={styles.canvas}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    canvasContainer: {
      position: 'relative',
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #e5e7eb',
      padding: '20px',
    } as CSSProperties,
    canvas: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      cursor: 'crosshair',
    } as CSSProperties,
  };
};

export default Canvas;

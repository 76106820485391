import { TReportTemplate } from '@/features/inspection/types';
import { fetchReportTemplates } from '@/features/inspectionList/services/inspectionList.service';
import { ContainerOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Card, Col, Flex, Row } from 'antd';
import Text from 'antd/es/typography/Text';
import { CSSProperties } from 'react';
import { getColorBrightness } from '../../util/styling';
import EditAndDownloadReport from './EditAndDownloadReport';

const ReportTemplatesTab = (
  props: TReportTemplate & {
    inspectionId: string | undefined;
    referenceNumber: string | undefined;
    vehicleNo: string | undefined;
  }
) => {
  const styles = useStyles();
  return (
    <Row align={'middle'} justify={'space-between'} gutter={8} wrap>
      <Col xl={16}>
        <Row align={'middle'} gutter={12}>
          <Col>
            <ContainerOutlined style={styles.icon(props?.color || '#F6F7F9')} />
          </Col>
          <Col span={19} style={styles.descriptionContainer}>
            <Flex vertical>
              <Text style={styles.title}>{props?.templateLabel}</Text>
              <Text style={styles.description}>{props?.description}</Text>
            </Flex>
          </Col>
        </Row>
      </Col>
      <Col xl={8} style={styles.button}>
        <EditAndDownloadReport {...props} />
      </Col>
    </Row>
  );
};

type ReportsTemplatesViewProps = {
  inspectionId: string | undefined;
  referenceNumber: string | undefined;
  vehicleNo: string | undefined;
};

const ReportsTemplatesView = ({ inspectionId, referenceNumber, vehicleNo }: ReportsTemplatesViewProps) => {
  const styles = useStyles();

  const { data: templates } = useQuery({
    queryKey: ['templates'],
    queryFn: () => fetchReportTemplates(),
  });

  return (
    <Card
      styles={styles.cardBody}
      title={
        <Row align={'middle'} gutter={12} style={styles.templateTabContainer}>
          <Col>
            <ContainerOutlined style={styles.icon('#46A700')} />
          </Col>
          <Col>
            <Flex vertical wrap>
              <Text style={styles.title}>Templates</Text>
              <Text style={styles.description}>Choose a template to generate a report</Text>
            </Flex>
          </Col>
        </Row>
      }
    >
      <Flex vertical gap={16}>
        {templates
          ?.filter((template) => template.templateName !== 'common')
          ?.map((template, index) => (
            <ReportTemplatesTab
              key={index}
              inspectionId={inspectionId}
              referenceNumber={referenceNumber}
              vehicleNo={vehicleNo}
              {...template}
            />
          ))}
      </Flex>
    </Card>
  );
};
const useStyles = () => {
  return {
    icon: (color: string) => {
      const brightness = getColorBrightness(color);
      const iconColor = brightness > 150 ? 'black' : 'white';
      return {
        justifyContent: 'center',
        backgroundColor: color,
        borderRadius: 4,
        color: iconColor,
        height: 35,
        width: 35,
      } as CSSProperties;
    },
    title: { fontWeight: 500, fontSize: 14, color: 'black' } as CSSProperties,
    description: {
      fontWeight: 400,
      fontSize: 12,
      color: 'black',
      opacity: 0.6,
    } as CSSProperties,
    templateTabContainer: {
      padding: '8px 0',
    } as CSSProperties,
    descriptionContainer: {
      alignContent: 'center',
    } as CSSProperties,
    cardBody: { body: { padding: 24 } as CSSProperties },
    button: {
      display: 'flex',
      justifyContent: 'end',
    } as CSSProperties,
  };
};
export default ReportsTemplatesView;

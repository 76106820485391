import { useAuth } from '@/config/AuthContext/useAuth';
import { queryClient } from '@/config/http/react-query.config';
import { useToastApi } from '@/hooks/useToastApi';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import InvoiceFooter from '@invoices/components/InvoiceFooter';
import InvoiceHeader from '@invoices/components/InvoiceHeader';
import { calculateSectionTotals, calculateTotals, formatCurrency, getCurrency } from '@invoices/util';
import { TInvoiceResponse, TInvoiceTypeDetailsResponse, TLineItem } from '@invoices/util/types';
import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Col, Divider, Flex, Form, Input, Popconfirm, Row, Table } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import isEmpty from 'lodash/isEmpty';
import {
  CSSProperties,
  ClassAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { updateOverallDiscount } from '../services/invoices.service';
import InvoiceViewSkeleton from '../skeletons/InvoiceViewSkeleton';
type InvoiceViewProps = {
  invoiceTypeDetails: TInvoiceTypeDetailsResponse | undefined;
  invoice: TInvoiceResponse | undefined;
  onDeleteLineItems: (lineItems: TLineItem[]) => void;
  onSelectToEdit?: (lineItem: TLineItem | undefined) => void;
  itemToEdit?: TLineItem;
  printMode?: boolean;
  setOverallDiscount?: (discount: number) => void;
};

const InvoiceView = ({
  invoiceTypeDetails,
  invoice,
  onDeleteLineItems,
  onSelectToEdit,
  itemToEdit,
  printMode,
  setOverallDiscount,
}: InvoiceViewProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const styles: Styles = useStyles();
  const [hideTableFooter, setHideTableFooter] = useState<boolean>(true);
  const [columns, setColumns] = useState<any[]>([]);
  const [form] = Form.useForm();

  const toastApi = useToastApi();

  const sortedLineItems = useMemo(() => {
    return invoice?.lineItems ? [...invoice.lineItems].sort((a, b) => (a.lineItemId ?? 0) - (b.lineItemId ?? 0)) : [];
  }, [invoice?.lineItems]);

  const calculateAmount = (invoiceTypeDetails: TInvoiceTypeDetailsResponse, item: TLineItem) => {
    if (invoiceTypeDetails.applyDiscountToTotal) {
      return item.unitPrice * item.quantity;
    } else {
      return item.unitPrice * item.quantity * (1 - (item.discount ?? 0) / 100);
    }
  };

  const dataSource = useMemo(() => {
    return sortedLineItems.map((item) => ({
      ...item,
      amount: invoiceTypeDetails ? calculateAmount(invoiceTypeDetails, item) : 0,
      key: item?.lineItemId,
    }));
  }, [sortedLineItems]);
  const {
    authState: { tenantId },
  } = useAuth();

  const currency = getCurrency(tenantId);
  const transformColumns = (columns: any[]) => {
    return columns.map((col) => {
      const title = eval(col.title); // Evaluate the dynamic title
      const render = col.render ? eval(col.render) : eval('null'); // Evaluate the dynamic render function

      return {
        title,
        dataIndex: col.dataIndex,
        key: col.key,
        width: col.width,
        hidden: col.hidden,
        align: col.align,
        render,
      };
    });
  };

  useEffect(() => {
    if (isEmpty(selectedRowKeys)) return;
    const sections: any = Object.keys(selectedRowKeys);
    // @ts-ignore
    //TODO: fix type issue
    if (sections?.length === 1 && selectedRowKeys[sections[0]]?.length === 1 && itemToEdit === undefined) {
      setSelectedRowKeys([]);
    }
  }, [itemToEdit, selectedRowKeys]);

  useEffect(() => {
    setColumns(transformColumns(invoiceTypeDetails?.tableColumns || []));
  }, [invoice, invoiceTypeDetails]);

  const FIXED_COLUMNS = columns;

  const { groupedData, aggregateTotals } = useMemo(() => {
    const groupedData = dataSource?.reduce((acc: { [key: string]: TLineItem[] }, item) => {
      const section = item.sectionCode || 'default';
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(item);
      return acc;
    }, {});

    // Calculate aggregate totals
    const aggregateTotals = Object.keys(groupedData || {}).reduce(
      (totals, section) => {
        const sectionData = groupedData[section];
        const sectionTotals = calculateTotals(sectionData);

        totals.adjustedTotalAmount += sectionTotals.adjustedTotalAmount;
        totals.adjustedTotalDiscount += sectionTotals.adjustedTotalDiscount;
        totals.unadjustedTotalAmount += sectionTotals.unadjustedTotalAmount;
        totals.unadjustedTotalDiscount += sectionTotals.unadjustedTotalDiscount;
        return totals;
      },
      {
        adjustedTotalAmount: 0,
        adjustedTotalDiscount: 0,
        unadjustedTotalAmount: 0,
        unadjustedTotalDiscount: 0,
      }
    );

    return { groupedData, aggregateTotals };
  }, [dataSource]);

  const rowSelection = (section: string) => ({
    selectedRowKeys: (selectedRowKeys[section as keyof React.Key] as unknown as string[]) || [],
    onChange: (newSelectedRowKeys: React.Key[]) => {
      const newValue: React.Key[] = {
        ...selectedRowKeys,
        [section]: newSelectedRowKeys,
      };
      setSelectedRowKeys(newValue);

      const oneItemArrays: any = Object.values(newValue)?.filter((arr) => Array.isArray(arr) && arr.length === 1);
      if (oneItemArrays.length === 1 && Object.values(newValue).every((arr) => Array.isArray(arr) && arr.length <= 1)) {
        const selectedRow = dataSource?.find((item: TLineItem) => item.key === oneItemArrays[0][0]);
        onSelectToEdit && onSelectToEdit(selectedRow || undefined);
        return;
      }
      onSelectToEdit && onSelectToEdit(undefined);
    },
  });

  const getSelectedItems = () => {
    return (
      Object.keys(selectedRowKeys)
        .flatMap((section) =>
          groupedData?.[section]?.filter((item: TLineItem) =>
            (selectedRowKeys[section as keyof React.Key] as unknown as number[])?.includes(item?.key as number)
          )
        )
        .filter(Boolean) || []
    );
  };

  const updateDiscountMutation = useMutation({
    mutationFn: (discount: number) => updateOverallDiscount(invoice?.invoiceId || 0, discount),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoice', invoice?.invoiceId] });
    },
    onError: () => {
      toastApi.open({
        type: 'error',
        content: 'Failed to update discount, please try again!',
        duration: 2,
      });
    },
  });

  return (
    <div style={styles.invoiceContainer(printMode)}>
      {invoice ? (
        <>
          {invoice && !printMode && (
            <InvoiceHeader
              additionalFields={invoice.additionalFields}
              attn={invoice.attn}
              companyRegNo={invoice.companyRegNo}
              fromAddress={invoice.fromAddress}
              fromContactInfo={invoice.fromContactInfo}
              fromName={invoice.fromName}
              invoiceDate={invoice.invoiceDate}
              invoiceNumber={invoice.invoiceNumber}
              taxRegNo={invoice.taxRegNo}
              toAddress={invoice.toAddress}
              toName={invoice.toName}
              invoiceId={invoice.invoiceId}
              invoiceTypeName={invoice.invoiceType?.invoiceTypeName || 'Invoice'}
            />
          )}
          {!printMode && <Divider style={{ margin: '16px' }} />}
          {groupedData && Object.keys(groupedData).length > 0 && (
            <Flex justify="flex-end" style={styles.titleContainer}>
              {printMode ? null : (
                <Flex gap={6}>
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => {
                      onDeleteLineItems(getSelectedItems() as TLineItem[]);
                      setSelectedRowKeys([]);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="default" icon={<DeleteOutlined />} disabled={!getSelectedItems().length}></Button>
                  </Popconfirm>
                  <Button
                    disabled={Object.keys(groupedData).length === 1}
                    type="default"
                    children={`${hideTableFooter ? 'Show' : 'Hide'} Section Totals`}
                    icon={hideTableFooter ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    onClick={() => setHideTableFooter(!hideTableFooter)}
                  />
                </Flex>
              )}
            </Flex>
          )}
          {groupedData && Object.keys(groupedData).length > 0 ? (
            Object.keys(groupedData)
              .sort((a, b) => (a === 'default' ? -1 : b === 'default' ? 1 : 0))
              .map((section, index) => {
                const sectionData = groupedData[section];
                const totals = calculateSectionTotals(sectionData);
                return (
                  <div key={section}>
                    {section !== 'default' && (
                      <Title level={5} style={{ margin: '12px 8px' }}>
                        {section}
                      </Title>
                    )}
                    <div style={{ overflow: 'hidden' }}>
                      <Table
                        tableLayout="fixed"
                        rowSelection={
                          printMode ? undefined : { type: 'checkbox', ...rowSelection(section), hideSelectAll: true }
                        }
                        dataSource={sectionData}
                        columns={FIXED_COLUMNS}
                        pagination={false}
                        size="small"
                        showHeader={index === 0}
                        footer={
                          hideTableFooter || Object.keys(groupedData).length === 1
                            ? undefined
                            : () => (
                                <Flex vertical justify="flex-end" style={{ margin: '8px 0px' }}>
                                  {(() => {
                                    const items = [
                                      {
                                        label: `Subtotal (${currency}):`,
                                        value: totals?.totalAmount?.toFixed(2) ?? '0.00',
                                      },

                                      ...(invoice?.invoiceTypeCode === 'TAX_INVOICE' ||
                                      invoice?.invoiceTypeCode === 'TAX_INVOICE_RE_INSPECTION' ||
                                      invoice?.invoiceTypeCode === 'TAX_INVOICE_PRE_REPAIR'
                                        ? [
                                            {
                                              label: `GST (9%):`,
                                              value: (totals?.totalAmount * 0.09).toFixed(2),
                                            },
                                          ]
                                        : [
                                            {
                                              label: `${section === 'default' ? '' : section} Discount (${currency}):`,
                                              value: totals?.totalDiscount?.toFixed(2) ?? '0.00',
                                            },
                                          ]),

                                      {
                                        label: `Net ${section === 'default' ? '' : section} Total (${currency}):`,
                                        value: (invoice?.invoiceTypeCode === 'TAX_INVOICE' ||
                                        invoice?.invoiceTypeCode === 'TAX_INVOICE_RE_INSPECTION' ||
                                        invoice?.invoiceTypeCode === 'TAX_INVOICE_PRE_REPAIR'
                                          ? totals?.totalAmount + totals?.totalAmount * 0.09
                                          : totals?.totalAmount - totals?.totalDiscount
                                        ).toFixed(2),
                                      },
                                    ];

                                    return items.map((item, index) => (
                                      <Row gutter={4} key={index} justify={'space-between'} style={styles.tableFooter}>
                                        <Col className="gutter-row" span={16}>
                                          <Text style={{ textTransform: 'capitalize' }}>{item.label}</Text>
                                        </Col>
                                        <Col className="gutter-row" span={8} style={{ textAlign: 'right' }}>
                                          <Text>{formatCurrency(Number(item.value), tenantId, true)}</Text>
                                        </Col>
                                      </Row>
                                    ));
                                  })()}
                                </Flex>
                              )
                        }
                      />
                    </div>
                  </div>
                );
              })
          ) : (
            <>
              {!isEmpty(invoice) && (
                <Table
                  dataSource={[]}
                  columns={FIXED_COLUMNS}
                  pagination={false}
                  size="small"
                  locale={{ emptyText: 'No data available' }}
                />
              )}
            </>
          )}
          {invoiceTypeDetails?.applyDiscountToTotal && !printMode && (
            <Form layout="horizontal">
              <Row gutter={8} align={'top'} style={{ marginTop: '16px' }} justify={'end'}>
                <Col span={8}>
                  <Form.Item
                    style={{ margin: 0 }}
                    hidden={!invoiceTypeDetails?.applyDiscountToTotal}
                    name="discount"
                    label="Total Discount (%)"
                    initialValue={invoice?.lineItems?.[0]?.discount}
                    rules={[
                      {
                        validator(_, value) {
                          return value === undefined || (Number(value) >= 0 && Number(value) <= 100)
                            ? Promise.resolve()
                            : Promise.reject(new Error('Discount must be between 0% and 100%'));
                        },
                      },
                    ]}
                  >
                    <Input
                      max={100}
                      min={0}
                      placeholder={'Discount'}
                      type="number"
                      onChange={(e) => {
                        const sanitizedValue = e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/^(\d+\.\d{0,2}).*/, '$1');
                        setOverallDiscount && setOverallDiscount(Number(sanitizedValue));
                        form.setFieldsValue({ discount: sanitizedValue });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    disabled={
                      form.getFieldValue('discount') === undefined ||
                      !(form.getFieldValue('discount') >= 0 && form.getFieldValue('discount') <= 100)
                    }
                    loading={updateDiscountMutation.isPending}
                    children={'Apply Discount'}
                    type="default"
                    onClick={() => {
                      updateDiscountMutation.mutate(form.getFieldValue('discount') || 0);
                    }}
                  />
                </Col>
              </Row>
            </Form>
          )}
          {dataSource && dataSource.length > 0 && (
            <Flex vertical align="flex-end" style={{ marginTop: '32px' }}>
              {(() => {
                const totalTableColumns =
                  invoice?.invoiceTypeCode === 'TAX_INVOICE' ||
                  invoice?.invoiceTypeCode === 'TAX_INVOICE_RE_INSPECTION' ||
                  invoice?.invoiceTypeCode === 'TAX_INVOICE_PRE_REPAIR' ||
                  invoice?.invoiceTypeCode === 'ESTIMATION' ||
                  invoice?.invoiceTypeCode === 'INVOICE'
                    ? [
                        {
                          title: '',
                          dataIndex: 'label',
                          key: 'label',
                          render: (text: string) => <Text>{text}</Text>,
                        },
                        {
                          title: `${columns[columns.length - 1]?.title}`,
                          dataIndex: 'value',
                          key: 'value',
                          align: 'right',
                          render: (value: string) => <Text>{formatCurrency(Number(value), tenantId, true)}</Text>,
                        },
                      ]
                    : [
                        {
                          title: '',
                          dataIndex: 'label',
                          key: 'label',
                          width: '40%',
                          render: (text: string) => <Text>{text}</Text>,
                        },
                        {
                          title: `${columns[columns.length - 2]?.title}`,
                          dataIndex: 'value',
                          key: 'value',
                          align: 'right',
                          width: '30%',
                          render: (value: string) => <Text>{formatCurrency(Number(value), tenantId, true)}</Text>,
                        },

                        {
                          title: `${columns[columns.length - 1]?.title}`,
                          dataIndex: 'adjustedAmount',
                          key: 'adjustedAmount',
                          align: 'right',
                          width: '30%',
                          render: (value: string) => <Text>{formatCurrency(Number(value), tenantId, true)}</Text>,
                        },
                      ];

                const items =
                  invoice?.invoiceTypeCode === 'TAX_INVOICE' ||
                  invoice?.invoiceTypeCode === 'TAX_INVOICE_RE_INSPECTION' ||
                  invoice?.invoiceTypeCode === 'TAX_INVOICE_PRE_REPAIR'
                    ? [
                        {
                          key: '1',
                          label: `Total Amount :`,
                          value: aggregateTotals.unadjustedTotalAmount.toFixed(2),
                          adjustedAmount: aggregateTotals.adjustedTotalAmount.toFixed(2),
                        },
                        {
                          key: '2',
                          label: `GST (9%) :`,
                          value: (aggregateTotals.unadjustedTotalAmount * 0.09).toFixed(2),
                          adjustedAmount: (aggregateTotals.adjustedTotalAmount * 0.09).toFixed(2),
                        },
                        {
                          key: '3',
                          label: `Grand Total :`,
                          value: (
                            aggregateTotals.unadjustedTotalAmount +
                            aggregateTotals.unadjustedTotalAmount * 0.09
                          ).toFixed(2),
                          adjustedAmount: (
                            aggregateTotals.adjustedTotalAmount +
                            aggregateTotals.adjustedTotalAmount * 0.09
                          ).toFixed(2),
                        },
                      ]
                    : [
                        {
                          key: '1',
                          label: `Total Amount :`,
                          value: aggregateTotals.unadjustedTotalAmount.toFixed(2),
                          adjustedAmount: aggregateTotals.adjustedTotalAmount.toFixed(2),
                        },
                        {
                          key: '2',
                          label: `Total Discount :`,
                          value: aggregateTotals.unadjustedTotalDiscount.toFixed(2),
                          adjustedAmount: aggregateTotals.adjustedTotalDiscount.toFixed(2),
                        },
                        {
                          key: '3',
                          label: `Grand Total :`,
                          value: (
                            aggregateTotals.unadjustedTotalAmount - aggregateTotals.unadjustedTotalDiscount
                          ).toFixed(2),
                          adjustedAmount: (
                            aggregateTotals.adjustedTotalAmount - aggregateTotals.adjustedTotalDiscount
                          ).toFixed(2),
                        },
                      ];

                return (
                  <div>
                    {columns && columns.length > 0 && (
                      <Table
                        size="small"
                        columns={(totalTableColumns as []) || []}
                        dataSource={items}
                        pagination={false}
                        style={{ border: 'none' }}
                        rowClassName={() => 'no-border-rows'}
                        components={{
                          header: {
                            cell: (
                              props: JSX.IntrinsicAttributes &
                                ClassAttributes<HTMLTableCellElement> &
                                ThHTMLAttributes<HTMLTableCellElement>
                            ) => (
                              <th
                                {...props}
                                style={{
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  textAlign: 'right',
                                  margin: '0',
                                  fontSize: '12px',
                                }}
                              />
                            ),
                          },
                          body: {
                            cell: (
                              props: JSX.IntrinsicAttributes &
                                ClassAttributes<HTMLTableCellElement> &
                                TdHTMLAttributes<HTMLTableCellElement>
                            ) => (
                              <td
                                {...props}
                                style={{
                                  border: 'none',
                                  textAlign: 'right',
                                  margin: '0',
                                  paddingBottom: '0',
                                  paddingTop: '0',
                                }}
                              />
                            ),
                          },
                        }}
                      />
                    )}
                  </div>
                );
              })()}
            </Flex>
          )}
          {invoice && !printMode && (
            <InvoiceFooter note={invoice?.customerNote} dueDate={invoice?.dueDate} terms={invoice?.terms} />
          )}
        </>
      ) : (
        <>
          <Alert
            message={
              'Use the right panel to add line items and edit the header/footer. Your updated invoice will appear here.'
            }
            type="info"
            showIcon
            style={{ marginBottom: '16px' }}
          />
          <InvoiceViewSkeleton />
        </>
      )}
    </div>
  );
};

export default InvoiceView;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    invoiceContainer: (printMode: boolean | undefined) =>
      ({
        width: printMode ? '100%' : '230mm',
        margin: '0 auto',
        boxShadow: printMode ? '' : '0px 0px 6px #ccc',
        padding: printMode ? '8px' : '32px',
      }) as CSSProperties,
    invoiceTitle: {
      margin: 0,
      lineHeight: 1,
    } as CSSProperties,
    titleContainer: {
      marginBottom: '14px',
    } as CSSProperties,
    syncStatusSuccess: {
      color: 'green',
      fontSize: '12px',
    } as CSSProperties,
    syncStatusPending: {
      color: 'red',
      fontSize: '12px',
      marginBottom: '8px',
    } as CSSProperties,
    invoiceStatusTag: {
      padding: '2px 6px',
      lineHeight: 1,
    } as CSSProperties,
    addLineItemButton: {
      width: '100%',
      borderRadius: '0',
    } as CSSProperties,
    tableFooter: {
      width: '30vw',
      justifySelf: 'flex-end',
      alignSelf: 'flex-end',
    },
  };
};

import {
  InspectionData,
  InspectionEvidenceData,
  TFieldValues,
  TInspectionReportSection,
} from '@/features/inspection/types';
import { TInvoiceResponse } from '@/features/invoices/util/types';
import { TLocation } from '@/types';
import startCase from 'lodash/startCase';

export const generateMapUrl = ({ lat, lng }: TLocation, zoom = 16, width = 600, height = 400) => {
  const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
  const markers = `color:red%7Clabel:C%7C${lat},${lng}`;
  return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${width}x${height}&markers=${markers}&key=${apiKey}`;
};

export const generateReportSections = (
  inspection: InspectionData,
  evidences: InspectionEvidenceData[],
  incidentDetails: TFieldValues[] = [],
  invoices?: TInvoiceResponse[]
): TInspectionReportSection[] => {
  // Function to map incidentDetails to TInspectionReportSection format
  const mapIncidentDetailsToReportSections = (details: TFieldValues[]): TInspectionReportSection[] => {
    return details.map((section) => {
      if (section.section === 'describeCircumstancesOfTheAccident') {
        // Special handling for describeCircumstancesOfTheAccident to make it a paragraph
        return {
          key: section.section,
          title: startCase(section.section),
          level: 1,
          type: 'paragraph',
          content: section.data[0]?.value || 'No content available',
        };
      }

      // Default handling for other sections
      return {
        key: section.section,
        title: startCase(section.section),
        level: 1,
        type: 'keyValuePairList',
        content: section.data.map((item) => ({
          title: startCase(item.name),
          description: item.value ? item.value.toString() : '-',
        })),
      };
    });
  };

  // Generate the sections for incident details
  const incidentDetailSections = incidentDetails.length ? mapIncidentDetailsToReportSections(incidentDetails) : [];

  return [
    ...incidentDetailSections,
    {
      key: 'accident-location',
      title: 'Accident Location',
      level: 2,
      type: 'map',
      content: inspection?.location,
    },
    {
      key: 'inspection-details',
      title: inspection?.inspectionDetails?.inspectionDetails?.title || '',
      level: 1,
      type: 'keyValuePairList',
      content: inspection?.inspectionDetails?.inspectionDetails?.data,
    },
    {
      key: 'job-description',
      title: 'Job Description',
      level: 1,
      type: 'jobDescription',
      width: 300,
      align: 'right',
      content: [
        { type: 'table', headers: ['Labour Code', 'Description'] },
        { type: 'singleImage', src: 'https://ds-nextcloud.s3.amazonaws.com/Dev/vehicle_inspection.jpg' },
      ],
    },
    {
      key: 'invoice',
      title: 'Invoices',
      level: 1,
      type: 'invoice',
      content: invoices,
    },
    {
      key: 'evidence',
      title: 'Evidence',
      level: 1,
      type: 'images',
      content: evidences,
    },
  ];
};

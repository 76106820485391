import Logo from '@/components/Logo';
import { useAuth } from '@/config/AuthContext/useAuth';
import env from '@/config/env';
import { getInvoices } from '@/features/invoices/services/invoices.service';
import { PrinterOutlined } from '@ant-design/icons';
import { fetchInspectionData, fetchInspectionEvidence } from '@inspection/services/inspections.service.ts';
import { fetchFieldValues } from '@inspectionList/services/inspectionList.service.ts';
import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Button, Card, Col, Flex, Row, Spin, Tooltip } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { CSSProperties, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import InspectionReportView from '../InspectionReportView';
import ReportsTemplatesView from '../components/ReportsManagement/ReportsTemplatesView';

const InspectionReportsLayout = () => {
  const styles = useStyles();
  const { uniqueCode } = useParams<{ uniqueCode: string }>();
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const {
    authState: { tenantId },
  } = useAuth();
  const [printMode, setPrintMode] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const saveAsPdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Inspection-Report-${Date.now()}`,
  });

  const { data: inspection, isFetching: isInspectionLoading } = useQuery({
    queryKey: ['inspection-data', uniqueCode],
    queryFn: () => fetchInspectionData(uniqueCode, tenantId),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
    enabled: !!uniqueCode,
  });

  const { data: invoices, isFetching: isInvoicesLoading } = useQuery({
    queryKey: ['inspection-invoices', uniqueCode],
    queryFn: () => getInvoices({ uniqueCode: uniqueCode as string }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
    enabled: !!uniqueCode,
  });

  const referenceNumber = inspection?.referenceNumber;

  const { data: fieldValues, isLoading: IsLoadingFieldValues } = useQuery({
    queryKey: ['fields-values', referenceNumber, 'common'],
    queryFn: () => fetchFieldValues(referenceNumber ?? '', 'common'),
    refetchOnMount: true,
    enabled: !!referenceNumber,
  });

  const { data: evidences, isFetching: isIncidentEvidenceLoading } = useQuery({
    queryKey: ['incident-evidence', referenceNumber],
    queryFn: () => fetchInspectionEvidence({ referenceNumber }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
    enabled: !!referenceNumber,
  });

  const print = () => {
    setPrintMode(true);
    setTimeout(() => {
      saveAsPdf();
      setTimeout(() => {
        setPrintMode(false);
      }, 500);
    }, 500);
  };

  return (
    <div style={styles.container}>
      <Flex justify="space-between" align="center" style={styles.buttonBar} gap={8}>
        <div>
          <Breadcrumb
            separator="/"
            items={[
              {
                title: 'Inspection Overview',
                href: `/inspection/`,
              },
              { title: 'Inspection', href: `/inspection/${uniqueCode}` },
              { title: 'Report' },
            ]}
            style={styles.breadCrumb}
          />
          <Title level={4} style={styles.mainTitle}>
            Inspections Report
          </Title>
        </div>
        <Flex gap={8}>
          <Tooltip title="Print" placement="bottom">
            <Button type="primary" children={'Print'} icon={<PrinterOutlined />} onClick={print} iconPosition="end" />
          </Tooltip>
        </Flex>
      </Flex>
      <Row justify="space-between" gutter={8} style={styles.card}>
        <Col sm={!env.VITE_NODE_ENV.includes('prod') || tenantId === 'ajax' ? 17 : 24}>
          <Card>
            <Flex ref={componentRef} vertical style={styles.invoiceContainer(printMode)}>
              <Flex style={styles.logo}>
                <Logo />
              </Flex>
              {isInspectionLoading || isInvoicesLoading || IsLoadingFieldValues || isIncidentEvidenceLoading ? (
                <Spin size="large" />
              ) : (
                inspection &&
                evidences && (
                  <InspectionReportView
                    printMode={printMode}
                    inspection={inspection}
                    invoices={invoices}
                    evidences={evidences}
                    incidentDetails={fieldValues ?? []}
                    hideItem={(value) => {
                      if (!hiddenItems.includes(value)) {
                        setHiddenItems((prevItems) => [...prevItems, value]);
                      }
                    }}
                    showItem={(value) => {
                      setHiddenItems((prevItems) => prevItems.filter((item) => item !== value));
                    }}
                  />
                )
              )}
              <Flex justify="center">
                <Text children={'Inspection Report'} />
              </Flex>
            </Flex>
          </Card>
        </Col>
        {(!env.VITE_NODE_ENV.includes('prod') || tenantId === 'ajax') && (
          <Col sm={7}>
            <ReportsTemplatesView
              inspectionId={uniqueCode}
              referenceNumber={inspection?.referenceNumber}
              vehicleNo={inspection?.vehicleNumber}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InspectionReportsLayout;

const useStyles = () => {
  return {
    invoiceContainer: (printMode: boolean | undefined) =>
      ({
        width: printMode ? '100%' : '230mm',
        margin: '0 auto',
        boxShadow: printMode ? '' : '0px 0px 6px #ccc',
        padding: printMode ? '8px' : '32px',
      }) as CSSProperties,
    container: {
      padding: '0px 16px',
      backgroundColor: '#F5F4F7',
    } as CSSProperties,
    mainTitle: {
      margin: 0,
      fontWeight: '500',
    } as CSSProperties,
    buttonBar: { marginTop: 16 } as CSSProperties,
    breadCrumb: { fontSize: '12px' } as CSSProperties,
    card: { marginTop: '8px' } as CSSProperties,
    cardBody: {
      margin: '1rem auto 1rem auto',
      maxWidth: '70%',
      boxShadow: '0px 0px 6px #ccc',
    } as CSSProperties,
    logo: { margin: '0 0 0 1rem' },
  };
};

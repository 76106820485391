/* eslint-disable @typescript-eslint/no-explicit-any */
import env from '@/config/env.ts';
import { DigiEyeApi } from '@/config/http/axios.api-config';
import { DigiEyeCore } from '@/config/http/axios.core-config';
import { DigiEyeUnencrypted } from '@/config/http/axios.unencrypted-config';
import { API_ROUTES_UNENCRYPTED, API_ROUTES_UPDATED } from '@/constants/API_ROUTES.ts';
import { fetchInspectionList } from '@/features/inspectionList/services/inspectionList.service';
import { TComments, TTags } from '@/features/inspectionList/types';
import { TLocation } from '@/types';
import {
  DownloadMediaParams,
  GetEvidenceParams,
  InspectionData,
  InspectionEvidenceData,
  TAddComment,
  TCompleteInspection,
  TDeleteMedia,
  TEventStatus,
  TMarkAsBlocked,
  TMarkAsInvalid,
  TMarkAsResolved,
  TMarkAsValid,
  TPointOfImpactResponse,
  UploadFiles,
} from '@inspection/types';
import { transformPolicyData } from '@inspection/utils/dataTransform.util';
import { transformTimLine } from '@inspection/utils/timeLine.util';
import { AxiosResponse } from 'axios';

export const fetchInspectionData = async (
  uniqueCode: string | undefined,
  tenantId: string
): Promise<InspectionData> => {
  const promises: [Promise<AxiosResponse>, Promise<AxiosResponse>] = [
    DigiEyeCore.get(API_ROUTES_UPDATED.GET_INSPECTION_ACCEPTED_USER, { params: { uniqueCode } }),
    DigiEyeApi.post(API_ROUTES_UPDATED.GET_INSPECTION_INFO, { uniqueCode }),
  ];

  const [acceptedBy, inspection] = await Promise.all(promises);

  const acceptedByResponseData = acceptedBy.data.data;
  const responseData = inspection.data.data;

  let referenceNumber = '';
  let vehicleNumber = '';
  responseData.inspectionData.inspectionDetails.map((item: { title: string; description: string }) => {
    switch (item.title) {
      case 'Job Number':
        referenceNumber = item.description;
        break;
      case 'Vehicle Number':
        vehicleNumber = item.description;
        break;
      default:
        break;
    }
  });

  return {
    accepted: responseData.accepted,
    acceptedBy: acceptedByResponseData?.acceptedBy || '',
    inspectionCode: responseData.inspectionCode,
    accidentDate: responseData.timeline[0].time,
    referenceNumber,
    vehicleNumber,
    timeLine: transformTimLine(responseData.timeline, tenantId),
    location: responseData.location,
    linkToAssessor: responseData.linkToAssessor,
    portalLink: responseData.link,
    submittedDraft: responseData.submittedDraft,
    inspectionDetails: {
      inspectionHandlers: { title: 'Handlers', data: responseData.inspectionHandlers },
      inspectionDetails: { title: 'Inspection details', data: responseData.inspectionData.inspectionDetails },
      operatorInputs: { title: 'Operator inputs', data: responseData.inspectionData.operatorInputs },
      assessorInputs: {
        title: !env.VITE_NODE_ENV.includes('prod') || tenantId === 'ajax' ? 'Surveyor inputs' : 'Assessor inputs',
        data: responseData.inspectionData.assessorInputs,
      },
    },
    policyDetails: {
      policyInformation: responseData.policyDetails
        ? responseData.policyDetails[0].data
          ? transformPolicyData(responseData.policyDetails[0])
          : { title: '', data: [] }
        : { title: '', data: [] },
      intimationDetails: responseData.policyDetails
        ? responseData.policyDetails[1].data
          ? transformPolicyData(responseData.policyDetails[1])
          : { title: '', data: [] }
        : { title: '', data: [] },
    },
    media: responseData.documents.map(
      (item: {
        src: string;
        thumbnail: string;
        docId: string;
        type: string;
        capturedAt: string;
        location: TLocation;
        source: string;
        comments: TComments;
        tags: TTags;
        deleted: boolean;
      }) => ({
        full: item.src,
        thumbnail: item.thumbnail,
        docId: item.docId,
        type: item.type,
        capturedAt: item.capturedAt,
        location: item.location,
        source: item.source,
        comments: item.comments,
        tags: item.tags,
        deleted: item?.deleted || false,
      })
    ),
    submitted: responseData?.submitted || false,
    blocked: responseData?.blocked || false,
    blockedReason: responseData.blockedReason,
    markedAsBlockedAt: responseData.markedAsBlockedAt,
    invalid: responseData?.invalid || false,
    invalidReason: responseData.invalidReason,
    markedAsInvalidAt: responseData.markedAsInvalidAt,
    comments: responseData.comments,
  };
};

export const fetchInspectionEvidence = async ({
  referenceNumber,
  inspectionId,
}: GetEvidenceParams): Promise<InspectionEvidenceData[]> => {
  return await DigiEyeApi.get(API_ROUTES_UPDATED.GET_INSPECTION_EVIDENCE, {
    params: {
      referenceNumber,
      ...(inspectionId ? { inspectionId } : {}),
    },
  }).then((responseData: any) => {
    if (responseData.data?.success === false)
      throw new Error(responseData.data?.message || 'Get Inspection Evidence failed');

    return responseData.data.data;
  });
};

export const fetchOtherInspections = async ({
  vehicleNumber,
  uniqueCode,
}: {
  vehicleNumber: string;
  uniqueCode: string;
}): Promise<
  {
    referenceNumber: string;
    inspectionType: string;
    uniqueCode: string;
    vehicleNumber: string;
    accidentDate: string;
    valuation: string;
    assessor: string;
    acceptedBy: string;
    offerStatus: boolean;
    assessorComment: string;
    submittedAt: string;
    status: string;
  }[]
> => {
  return await fetchInspectionList({ limit: 10, page: 1, vehicleNumber })
    .then((data) => data.data)
    .then((data) => data.filter((item: { uniqueCode: string }) => item.uniqueCode !== uniqueCode))
    .then((data) => {
      return (data || []).map(
        (item: {
          referenceNumber: string;
          inspectionType: string;
          uniqueCode: string;
          vehicleNumber: string;
          accidentDate: string;
          valuation: string;
          assessor: string;
          acceptedBy: string;
          offerStatus: boolean;
          assessorComment: string;
          submittedAt: string;
          status: string;
        }) => ({
          referenceNumber: item.referenceNumber,
          inspectionType: item.inspectionType,
          uniqueCode: item.uniqueCode,
          vehicleNumber: item.vehicleNumber,
          accidentDate: item.accidentDate,
          valuation: item.valuation,
          assessor: item.assessor,
          acceptedBy: item.acceptedBy,
          offerStatus: item.offerStatus,
          assessorComment: item.assessorComment,
          submittedAt: item.submittedAt,
          status: item.status,
        })
      );
    });
};

export const inspectionAssignToMe = async ({
  uniqueCode,
}: {
  uniqueCode: string;
}): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(API_ROUTES_UPDATED.INSPECTION_ASSIGN_TO_ME, {
    uniqueCode: uniqueCode,
  }).then((data: any) => ({ success: data.data.success, message: data.data.message }));
};

export const downloadAllMedia = async ({ uniqueCode }: DownloadMediaParams): Promise<void> => {
  await DigiEyeUnencrypted.get(API_ROUTES_UNENCRYPTED.GET_All_MEDIA, {
    params: { uniqueCode },
  }).then((data: any) => {
    if (data.data?.success === false) throw new Error(data.data?.message || 'Bulk media download failed');
    const { link } = data.data.data;
    window.open(link?.downloadLink, '_blank');
  });
};

export const uploadImages = async ({ uniqueCode, files }: UploadFiles) => {
  try {
    if (files && files.length < 1) throw new Error('Please select documents to upload');
    const formData = new FormData();
    formData.append('uniquecode', uniqueCode);
    formData.append('masking', env.VITE_MASKING_KEY);
    files.forEach((file: File) => {
      formData.append('files', file);
    });

    await DigiEyeUnencrypted.post(API_ROUTES_UNENCRYPTED.UPLOAD_DOCUMENTS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        uniqueCode: uniqueCode,
        masking: env.VITE_MASKING_KEY,
      },
    }).then((data: any) => {
      if (data.data.success) return { success: data.data.success, message: data.data.message };
    });
  } catch (e) {
    return { success: false, message: e instanceof Error ? e.message : 'Error' };
  }
};

export const completeInspection = async ({
  uniqueCode,
  form,
}: TCompleteInspection): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(
    API_ROUTES_UNENCRYPTED.COMPLETE_INSPECTION,
    JSON.stringify({ ...form, uniqueCode })
  ).then((data: any) => {
    return { success: data.data.success, message: data.data.message };
  });
};

export const saveSortedMedia = async (payload: SaveSortedMediaPayload) => {
  return await DigiEyeCore.put(API_ROUTES_UPDATED.SAVE_SORTED_MEDIA, payload).then((res) => ({
    success: res.data.success,
    message: res.data.message,
  }));
};

export const markAsBlocked = async ({
  uniqueCode,
  reason,
}: TMarkAsBlocked): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(
    API_ROUTES_UNENCRYPTED.MARK_AS_BLOCKED,
    JSON.stringify({ uniqueCode, reason })
  ).then((data: any) => {
    return { success: data.data.success, message: data.data.message };
  });
};

export type SaveSortedMediaPayload = { uniqueCode: string; media: { docId: string; order: number }[] };

export const markedAsResolved = async ({
  uniqueCode,
}: TMarkAsResolved): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(API_ROUTES_UNENCRYPTED.MARK_AS_RESOLVED, JSON.stringify({ uniqueCode })).then(
    (data: any) => {
      return { success: data.data.success, message: data.data.message };
    }
  );
};

export const markAsInvalid = async ({
  uniqueCode,
  reason,
}: TMarkAsInvalid): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(
    API_ROUTES_UNENCRYPTED.MARK_AS_INVALID,
    JSON.stringify({ uniqueCode, reason })
  ).then((data: any) => {
    return { success: data.data.success, message: data.data.message };
  });
};

export const markAsValid = async ({ uniqueCode }: TMarkAsValid): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(API_ROUTES_UNENCRYPTED.MARK_AS_VALID, JSON.stringify({ uniqueCode })).then(
    (data: any) => {
      return { success: data.data.success, message: data.data.message };
    }
  );
};

export const addComment = async ({
  uniqueCode,
  docId,
  comment,
  docComment,
  sendNotification,
  notificationRole,
  isPriorityComment,
}: TAddComment): Promise<{ success: boolean; message: string }> => {
  if (comment === null) throw new Error('Please enter comment');

  return await DigiEyeUnencrypted.post(
    API_ROUTES_UNENCRYPTED.ADD_COMMENT,
    JSON.stringify({
      uniqueCode,
      docId,
      comment,
      docComment,
      sendNotification,
      notificationRole,
      isPriorityComment,
    })
  ).then((data: { data: { success: boolean; message: any } }) => {
    if (data.data.success === false) throw new Error(data.data.message || 'Something went wrong!');
    return { success: data.data.success, message: data.data.message };
  });
};

export const deleteMedia = async ({
  uniqueCode,
  docId,
}: TDeleteMedia): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(API_ROUTES_UNENCRYPTED.DELETE_MEDIA, JSON.stringify({ uniqueCode, docId })).then(
    (data: any) => {
      return { success: data.data.success, message: data.data.message };
    }
  );
};

export const getEventStatuses = async (): Promise<any[]> => {
  return await DigiEyeCore.get(`${API_ROUTES_UPDATED.EVENT_STATUSES}`).then((res) => {
    return res.data.data;
  });
};

export const getCurrentEventStatuses = async (inspectionId: string): Promise<{ eventStatus: string }> => {
  return await DigiEyeCore.get(`${API_ROUTES_UPDATED.GET_CURRENT_EVENT_STATUS}${inspectionId}`).then((res) => {
    return res.data.data;
  });
};

export const updateEventStatus = async ({
  inspectionId,
  status,
}: TEventStatus): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeCore.put(API_ROUTES_UPDATED.EVENT_STATUSES, JSON.stringify({ inspectionId, status })).then(
    (data: any) => {
      return { success: data.data.success, message: data.data.message };
    }
  );
};

export const uploadPointOfImpact = async ({ uniqueCode, files }: UploadFiles) => {
  try {
    if (files && files.length < 1) throw new Error('Please select documents to upload');
    const formData = new FormData();
    formData.append('uniquecode', uniqueCode);
    formData.append('masking', env.VITE_MASKING_KEY);
    formData.append('pointOfImpact', 'true');

    files.forEach((file: File) => {
      formData.append('files', file);
    });

    await DigiEyeUnencrypted.post(API_ROUTES_UNENCRYPTED.UPLOAD_DOCUMENTS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        uniqueCode: uniqueCode,
        masking: env.VITE_MASKING_KEY,
        pointOfImpact: 'true',
      },
    }).then((data: any) => {
      if (data.data.success) return { success: data.data.success, message: data.data.message };
    });
  } catch (e) {
    return { success: false, message: e instanceof Error ? e.message : 'Error' };
  }
};

export const fetchPontOfImpact = async ({
  referenceNumber,
  inspectionId,
}: GetEvidenceParams): Promise<TPointOfImpactResponse[]> => {
  return await DigiEyeApi.get(API_ROUTES_UPDATED.GET_INSPECTION_EVIDENCE, {
    params: {
      referenceNumber,
      ...(inspectionId ? { inspectionId } : {}),
      pointOfImpact: true,
    },
  }).then((responseData: any) => {
    if (responseData.data?.success === false)
      throw new Error(responseData.data?.message || 'Get Inspection Evidence failed');

    return responseData.data.data;
  });
};

export const deletePOIImage = async ({
  uniqueCode,
  docId,
}: TDeleteMedia): Promise<{ success: boolean; message: string }> => {
  return await DigiEyeUnencrypted.post(API_ROUTES_UNENCRYPTED.DELETE_POI, JSON.stringify({ uniqueCode, docId })).then(
    (data: any) => {
      return { success: data.data.success, message: data.data.message };
    }
  );
};

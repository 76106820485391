import { useAuth } from '@/config/AuthContext/useAuth';
import { queryClient } from '@/config/http/react-query.config';
import { TComments } from '@/features/inspectionList/types';
import { useToastApi } from '@/hooks/useToastApi';
import { addComment } from '@inspection/services/inspections.service';
import { TAddComment } from '@inspection/types';
import { getColorForName, getInitials } from '@inspection/utils/auth.util';
import { getCurrentUser } from '@inspection/utils/auth.util.ts';
import { useMutation } from '@tanstack/react-query';
import { Avatar, Badge, Button, Flex, Form, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import isEmpty from 'lodash/isEmpty';
import { CSSProperties, useEffect, useState } from 'react';
dayjs.extend(RelativeTime);

type Props = {
  uniqueCode: string;
  commentList: TComments | [];
};

const InspectionComments = ({ uniqueCode, commentList = [] }: Props) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  const [comments, setComments] = useState<TComments>(commentList);
  const [failedComment, setFailedComment] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentUser, setCurrentUser] = useState<string>('');
  const toastApi = useToastApi();
  const {
    authState: { token },
  } = useAuth();

  useEffect(() => {
    if (token) {
      setCurrentUser(getCurrentUser(token).name);
    }
  }, [token, currentUser]);

  const mutation = useMutation({
    mutationFn: ({ uniqueCode, comment }: TAddComment) =>
      addComment({ uniqueCode, comment, docComment: false, isPriorityComment: false }),
    onSuccess: () => {
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ['inspection-data'] });
      setFailedComment(null);
      setErrorMessage('');
    },
    onError: (data) => {
      setFailedComment(data);
      setErrorMessage('Failed to add comment, please try again!');
      toastApi.open({
        type: 'error',
        content: data?.message || 'Failed to add comment!',
        duration: 2,
      });
      setComments(comments.slice(1));
    },
  });

  const styles = useStyles();

  const onFinish = (values: any) => {
    if (!values.comment) {
      setErrorMessage('Please enter a comment');
      return;
    }
    mutation.mutate({
      uniqueCode,
      comment: values.comment,
      docComment: false,
    });
    setComments([
      {
        name: currentUser,
        comment: values.comment,
        commentedAt: dayjs().toISOString(),
        isPriorityComment: false,
      },
      ...comments,
    ]);
  };

  return (
    <Flex vertical style={styles.commentsContainer}>
      <Flex style={styles.commentsTitleContainer} gap={8} align="center">
        <Title level={5} style={styles.commentsTitle}>
          Comments
        </Title>
        <Badge count={comments.length} />
      </Flex>
      <Flex>
        {comments && comments.length > 0 ? (
          <Flex vertical style={styles.commentsList} gap={20}>
            {comments.map((singleComment, index) => {
              const commentedAt = dayjs(singleComment.commentedAt);
              return (
                <Flex key={index} style={styles.commentContainer} gap={8}>
                  <Avatar
                    style={styles.avatar(getColorForName(singleComment.name))}
                    children={getInitials(singleComment.name)}
                  />
                  <Flex vertical gap={4}>
                    <Flex vertical>
                      <Title level={5} style={styles.usernameTextStyle}>
                        {singleComment.name}
                      </Title>
                      <Text style={styles.titleTextStyle}>{commentedAt.from(dayjs())}</Text>
                    </Flex>
                    <Text style={styles.commentTextStyle}>{singleComment.comment}</Text>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <Text>No comments available</Text>
        )}
      </Flex>
      <Form form={form} layout="vertical" onFinish={(values) => onFinish(values)} style={styles.form}>
        <Flex gap={8}>
          <Avatar style={styles.avatar(getColorForName(currentUser))} children={getInitials(currentUser)} />
          <Form.Item
            style={styles.commentInput}
            name={'comment'}
            validateStatus={errorMessage ? 'error' : ''}
            help={errorMessage || undefined}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 6 }}
              placeholder="Write down a comment"
              draggable={false}
              onChange={(value) => {
                if (isEmpty(value)) {
                  setErrorMessage('');
                  setFailedComment(null);
                }
              }}
            />
          </Form.Item>
        </Flex>
        <Flex justify="flex-end" gap={8}>
          <Form.Item>
            <Button type="default" htmlType="reset" children="Cancel" disabled={mutation.isPending} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
              children={failedComment ? 'Resend' : 'Add a comment'}
            />
          </Form.Item>
        </Flex>
      </Form>
    </Flex>
  );
};
const useStyles = () => {
  return {
    titleTextStyle: {
      fontSize: 12,
      color: 'black',
      opacity: 0.4,
    } as CSSProperties,
    commentIconStyle: {
      fontSize: '16px',
      color: '#0035CC',
    } as CSSProperties,
    usernameTextStyle: {
      margin: 0,
      fontSize: '12px',
    } as CSSProperties,
    commentTextStyle: {
      textAlign: 'left',
      fontSize: '12px',
      color: '#5B5D68',
    } as CSSProperties,
    commentsList: {
      flexDirection: 'column-reverse',
    } as CSSProperties,
    commentContainer: {
      borderRadius: '8px',
      maxWidth: 'fit-content',
    } as CSSProperties,
    commentInput: {
      width: '100%',
      margin: '4px 0px 16px 0px',
    } as CSSProperties,
    form: {
      marginTop: '28px',
      width: '100%',
    } as CSSProperties,
    commentsTitle: {
      marginTop: '8px',
    } as CSSProperties,
    commentsContainer: { padding: '16px' } as CSSProperties,
    avatar: (color: string) => ({
      backgroundColor: color,
      fontSize: 12,
      minWidth: '32px',
      minHeight: '32px',
      marginTop: '4px',
    }),
    commentsTitleContainer: {
      marginBottom: '20px',
    } as CSSProperties,
  };
};
export default InspectionComments;

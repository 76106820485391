//This function is used to get the brightness of a color. It is used to determine the color of the text/icon on top of the color.

export const getColorBrightness = (color: string): number => {
  // Convert hex color to RGB
  let r: number, g: number, b: number;

  if (color.startsWith('#')) {
    // Remove the "#" and parse the color
    const hex = color.slice(1);
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    // If it's an RGB color string
    const rgb = color.match(/\d+/g);
    if (rgb) {
      r = parseInt(rgb[0], 10);
      g = parseInt(rgb[1], 10);
      b = parseInt(rgb[2], 10);
    } else {
      r = g = b = 0; // Fallback if color is invalid
    }
  }

  // Calculate brightness using the luminance formula
  const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return brightness;
};

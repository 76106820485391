import CurrencySelectInput from '@/components/CurrencySelectInput';
import { useAuth } from '@/config/AuthContext/useAuth';
import { queryClient } from '@/config/http/react-query.config';
import { useToastApi } from '@/hooks/useToastApi';
import { PlusOutlined } from '@ant-design/icons';
import { createLineItem, updateLineItem, updateOverallDiscount } from '@invoices/services/invoices.service';
import { getCurrency } from '@invoices/util';
import { TInvoiceForm, TLineItem } from '@invoices/util/types';
import { useMutation } from '@tanstack/react-query';
import { AutoComplete, Button, Checkbox, Col, Divider, Flex, Form, Input, Row, Select, Space } from 'antd';
import type { InputRef } from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

type TInvoiceFormProps = {
  fields: any[];
  itemToEdit?: TLineItem;
  sections?: { value: string; label: string }[];
  onUpdate?: () => void;
  discount?: number;
  applyDiscountToTotal?: boolean;
};

const InvoiceForm = ({ fields, sections, itemToEdit, onUpdate, discount, applyDiscountToTotal }: TInvoiceFormProps) => {
  const [form] = Form.useForm();
  const mode = itemToEdit ? 'EDIT' : 'ADD';
  const { uniqueCode } = useParams<{ uniqueCode: string }>();
  const {
    authState: { tenantId },
  } = useAuth();
  const inputRefs = {
    name: useRef<InputRef>(null),
    description: useRef<TextAreaRef>(null),
    quantity: useRef<InputRef>(null),
    unitPrice: useRef<InputRef>(null),
    discount: useRef<InputRef>(null),
  };
  const initialValues = {
    sectionCode: '',
    itemName: '',
    itemDescription: '',
    quantity: 1,
    unitPrice: 0,
    discount: discount || 0,
    amountAdjustment: 0,
  };
  const toastApi = useToastApi();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const searchParams = new URLSearchParams(location.search);
  const invoiceId = searchParams.get('invoiceId') ? parseInt(searchParams.get('invoiceId') as string) : undefined;
  const currency = getCurrency(tenantId);
  const [isOverallDiscount, setIsOverallDiscount] = useState(false);
  const [overallDiscount, setOverallDiscount] = useState(0);

  const getFormFieldLabelByName = (name: string) => {
    const fieldLabel = fields?.find((field) => field.dataIndex === name)?.title;
    return eval(fieldLabel?.toString() || '') || name;
  };

  const updateDiscountMutation = useMutation({
    mutationFn: (discount: number) => updateOverallDiscount(invoiceId || 0, discount),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] });
    },
    onError: () => {
      toastApi.open({
        type: 'error',
        content: 'Failed to update discount, please try again!',
        duration: 2,
      });
    },
  });

  const updateLineItems = async () => {
    if (isOverallDiscount && overallDiscount >= 0) {
      form.setFieldsValue({ discount: overallDiscount });
      updateDiscountMutation.mutate(overallDiscount);
    }
  };

  useEffect(() => {
    if (!itemToEdit) {
      form.resetFields();
      return;
    }
    form.setFieldsValue(itemToEdit);
  }, [itemToEdit, mode, form]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    nextInput: React.RefObject<InputRef | TextAreaRef | HTMLButtonElement> | null
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (nextInput) {
        nextInput.current?.focus();
      } else {
        buttonRef.current?.focus();
        buttonRef.current?.click();
      }
    }
  };
  const hideFormItem = (dataIndex: string) => {
    const object = fields?.find((field) => field.dataIndex === dataIndex);
    return object?.hidden !== undefined ? object.hidden : true;
  };

  const mutation = useMutation({
    mutationFn: (lineItem: TInvoiceForm) =>
      mode === 'ADD' ? createLineItem(invoiceId, lineItem) : updateLineItem(invoiceId, lineItem, itemToEdit?.id),
    onSuccess: async () => {
      await updateLineItems();

      if (mode === 'EDIT' && onUpdate) onUpdate();
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] });
      queryClient.invalidateQueries({ queryKey: ['inspection-invoices', uniqueCode] });
      form.resetFields();
    },
    onError: (data) => {
      toastApi.open({
        type: 'error',
        content: data?.message || 'Failed to add!',
        duration: 2,
      });
    },
  });

  const onFinish = (values: TInvoiceForm) => {
    if (applyDiscountToTotal) {
      values.discount = discount || 0;
    } else if (isOverallDiscount) {
      values.discount = overallDiscount || 0;
    }
    if (Object.prototype.hasOwnProperty.call(values, 'itemDescription') && !values.itemDescription) {
      delete values.itemDescription;
    }
    mutation.mutate({ ...values, quantity: values?.quantity || 1 });
  };

  const handleBlur = async (fieldName: string) => {
    try {
      await form.validateFields([fieldName]);
    } catch (error) {
      if (!form.getFieldValue(fieldName)) {
        form.resetFields([fieldName]);
      }
    }
  };
  //TODO:This feature will be implemented separately
  let index = 0;
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);
  const [items, setItems] = useState<string[]>([]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form initialValues={initialValues} form={form} layout="vertical" onFinish={onFinish} style={{ width: '100%' }}>
      <Form.Item label={'Section'} name={'sectionCode'}>
        <AutoComplete
          options={sections}
          placeholder="Section"
          filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
      </Form.Item>
      <Form.Item
        hidden={hideFormItem('itemName')}
        name={'itemName'}
        label={getFormFieldLabelByName('itemName')}
        rules={[{ required: true, message: 'Please enter the item name' }]}
      >
        <Input
          maxLength={300}
          showCount
          placeholder={getFormFieldLabelByName('itemName')}
          ref={inputRefs.name}
          onKeyDown={(e) => handleKeyDown(e, inputRefs.description)}
          onBlur={() => handleBlur('name')}
        />
      </Form.Item>
      <Form.Item
        hidden={hideFormItem('itemDescription')}
        name="itemDescription"
        label={getFormFieldLabelByName('itemDescription')}
      >
        {fields?.find((field) => field.dataIndex === 'itemDescription')?.options ? (
          // <Select
          //   options={fields?.find((field) => field.dataIndex === 'itemDescription')?.options}
          //   placeholder={getFormFieldLabelByName('itemDescription')}
          //   onBlur={() => handleBlur('description')}
          // />
          //TODO:This feature will be implemented separately
          <Select
            placeholder="custom dropdown render"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Input
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Add item
                  </Button>
                </Space>
              </>
            )}
            options={[
              ...items.map((item) => ({ label: item, value: item })),
              ...(fields?.find((field) => field.dataIndex === 'itemDescription')?.options || []),
            ]}
          />
        ) : (
          <Input.TextArea
            showCount
            maxLength={500}
            placeholder={getFormFieldLabelByName('itemDescription')}
            ref={inputRefs.description}
            onKeyDown={(e) => handleKeyDown(e, inputRefs.quantity)}
            onBlur={() => handleBlur('description')}
          />
        )}
      </Form.Item>
      <Row gutter={8} align={'bottom'}>
        {!hideFormItem('quantity') && (
          <Col span={12}>
            <Form.Item
              hidden={hideFormItem('quantity')}
              name="quantity"
              label={getFormFieldLabelByName('quantity')}
              rules={[
                { required: true, message: 'Please enter the quantity' },
                {
                  validator(_, value) {
                    return !value || Number(value) >= 1
                      ? Promise.resolve()
                      : Promise.reject(new Error('Quantity must be greater than 0'));
                  },
                },
              ]}
            >
              <Input
                placeholder={getFormFieldLabelByName('quantity')}
                type="number"
                ref={inputRefs.quantity}
                onKeyDown={(e) => handleKeyDown(e, inputRefs.unitPrice)}
                onBlur={() => handleBlur('quantity')}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item
            hidden={hideFormItem('unitPrice')}
            name="unitPrice"
            label={getFormFieldLabelByName('unitPrice')}
            rules={[
              { required: true, message: 'Please enter the unit price' },
              {
                validator(_, value) {
                  return !value || Number(value) > 0
                    ? Promise.resolve()
                    : Promise.reject(new Error('Unit price must be greater than 0'));
                },
              },
            ]}
          >
            <CurrencySelectInput
              defaultCurrency={currency}
              placeholder={getFormFieldLabelByName('unitPrice')}
              onKeyDown={(e) => handleKeyDown(e, inputRefs.discount)}
              onBlur={() => handleBlur('unitPrice')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Checkbox
            style={{ position: 'absolute', left: 4, top: 28, display: hideFormItem('discount') ? 'none' : undefined }}
            checked={isOverallDiscount}
            onChange={(e) => {
              setIsOverallDiscount(e.target.checked);
              if (e.target.checked) {
                form.setFieldsValue({ discount: overallDiscount });
              }
            }}
          >
            Apply same discount to all items
          </Checkbox>
          <Form.Item
            hidden={hideFormItem('discount')}
            name="discount"
            label={getFormFieldLabelByName('discount')}
            rules={[
              {
                validator(_, value) {
                  return value === undefined || (Number(value) >= 0 && Number(value) <= 100)
                    ? Promise.resolve()
                    : Promise.reject(new Error('Discount must be between 0% and 100%'));
                },
              },
            ]}
          >
            <Input
              style={{ marginTop: 24 }}
              max={100}
              placeholder={getFormFieldLabelByName('discount')}
              type="number"
              value={isOverallDiscount ? overallDiscount : undefined}
              ref={inputRefs.discount}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '').replace(/^(\d+\.\d{0,2}).*/, '$1');

                isOverallDiscount
                  ? setOverallDiscount(Number(sanitizedValue))
                  : form.setFieldsValue({ discount: sanitizedValue });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            hidden={hideFormItem('amountAdjustment')}
            name="amountAdjustment"
            label={getFormFieldLabelByName('amountAdjustment')}
            rules={[
              {
                validator(_, value) {
                  return !value || Number(value) > 0
                    ? Promise.resolve()
                    : Promise.reject(new Error('Amount adjustment must be greater than 0'));
                },
              },
            ]}
          >
            <CurrencySelectInput
              defaultCurrency={currency}
              placeholder={getFormFieldLabelByName('amountAdjustment')}
              onBlur={() => handleBlur('amountAdjustment')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Flex justify="end" gap={8}>
        <Button htmlType="reset">Clear</Button>
        <Button ref={buttonRef} htmlType="submit" type="primary" loading={mutation.isPending}>
          {mode === 'ADD' ? 'Add Item' : 'Update Item'}
        </Button>
      </Flex>
    </Form>
  );
};

export default InvoiceForm;

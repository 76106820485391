import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { ReactNode } from 'react';

export type InspectionTimeLineItem = {
  completed: boolean;
  first: boolean;
  label: string;
  last: boolean;
  next: boolean;
  red: boolean;
  time: string | null;
};

export type InspectionTimeLine = InspectionTimeLineItem[];

export type TimeLineItem = {
  title: string;
  description: string | null;
  status: 'finish' | 'wait' | 'error';
};

export type TimeLine = TimeLineItem[];

export const transformTimLine = (values: InspectionTimeLine, tenantId: string): TimeLine => {
  return values.map((item) => {
    const title = tenantId === 'ajax' ? item.label.replace(/assessor/gi, 'Surveyor') : item.label;
    return {
      title,
      description: item.time !== null ? item.time : 'Pending',
      status: item.label === 'Rejected' ? 'error' : item.completed ? 'finish' : 'wait',
    };
  });
};

export const getStepIcon = (value: TimeLineItem): ReactNode => {
  if (value.title === 'Rejected') return <CloseCircleFilled />;
  else if (value.status === 'finish') return <CheckCircleFilled />;
  else return <ClockCircleFilled />;
};

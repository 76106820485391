import { KeyValuePairList, TFieldValues, TIncidentDetails, TIncidentDetailsField } from '../types';

export const transformPolicyData = (values: {
  title: string;
  data: [{ title: string; value: string }];
}): { title: string; data: KeyValuePairList } => {
  return {
    title: values.title,
    data: values.data.map((item) => ({
      title: item.title,
      description: item.value,
    })),
  };
};

export const combineFieldsWithValues = (
  fieldDefinitions: TIncidentDetailsField[],
  fieldValues: TFieldValues[]
): TIncidentDetails => {
  // Create a map for quick lookup of field values by field name
  const fieldValueMap = new Map<string, string | number>();

  // Populate the map with values from the fieldValues response
  fieldValues.forEach((section) => {
    section.data.forEach((item) => {
      fieldValueMap.set(item.name, item.value);
    });
  });

  // Combine field definitions with their corresponding values
  const combinedFields: TIncidentDetails = {};

  fieldDefinitions.forEach((field) => {
    // Get the value from the map, if it exists
    const fieldValue = fieldValueMap.get(field.fieldName);

    // Create a new field with the value populated
    const combinedField: TIncidentDetailsField = {
      ...field,
      value: fieldValue || undefined, // Assign value if available, else keep it undefined
    };

    // Group by section name
    field.sections.forEach((sectionName) => {
      if (!combinedFields[sectionName]) {
        combinedFields[sectionName] = [];
      }
      combinedFields[sectionName].push(combinedField);
    });
  });

  return combinedFields;
};

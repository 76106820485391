import ImageAnnotator from '@/components/ImageAnnotator/ImageAnnotator';
import { InfoCircleFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { CSSProperties } from 'react';
import IncidentDetailsForm from './IncidentDetailsForm';

type Props = {
  referenceNumber: string;
  uniqueCode: string;
};

function IncidentDetailsContainer({ referenceNumber, uniqueCode }: Props) {
  const styles = useStyles();

  return (
    <div>
      <Divider style={styles.divider} dashed orientation="left" orientationMargin="0">
        <Title style={styles.modalTitle} level={5}>
          {'Point of Impact'}
        </Title>
      </Divider>
      <ImageAnnotator uniqueCode={uniqueCode} referenceNumber={referenceNumber} />
      <div style={styles.alert}>
        <InfoCircleFilled style={styles.iconColor} />
        <Text
          style={styles.infoText}
          children={
            ' Tip: Hold Shift while drawing to create perfectly straight arrows (horizontal, vertical, or diagonal).'
          }
        />
      </div>
      <IncidentDetailsForm referenceNumber={referenceNumber} templateName="common" />
    </div>
  );
}

const useStyles = () => {
  return {
    modalTitle: { margin: 0 } as CSSProperties,
    divider: { borderColor: '#0035cc', margin: '0 0 32px 0' } as CSSProperties,
    alert: { margin: '2px 6px' } as CSSProperties,
    iconColor: { color: '#64748b' } as CSSProperties,
    infoText: { color: '#64748b', fontSize: '12px' } as CSSProperties,
  };
};

export default IncidentDetailsContainer;

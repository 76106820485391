import Icon from '@ant-design/icons/lib/components/Icon';
import { Badge, Slider, Typography } from 'antd';
interface ThicknessControlProps {
  thickness: number;
  onThicknessChange: (value: number) => void;
}

const ThicknessControl = ({ thickness, onThicknessChange }: ThicknessControlProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '250px' }}>
      <Icon component={() => <img src="/icons/line-thickness.svg" width={'18px'} height={'22px'} />} />
      <Typography.Text>Stroke width</Typography.Text>
      <Badge count={thickness} color="gray" />
      <Slider min={1} max={15} value={thickness} onChange={onThicknessChange} style={{ flex: 1 }} />
    </div>
  );
};

export default ThicknessControl;

import ContentOverlay from '@/components/ContentOverlay';
import StatisticGrid from '@/components/StatisticGrid';
import { useAuth } from '@/config/AuthContext/useAuth';
import { InspectionStatus } from '@/features/inspectionList/constants';
import { useToastApi } from '@/hooks/useToastApi';
import { CommentOutlined, FileDoneOutlined, UserAddOutlined } from '@ant-design/icons';
import CompleteInspection from '@inspection/components/ActionBar/CompleteInspection';
import { inspectionAssignToMe } from '@inspection/services/inspections.service';
import { InspectionData, TSubmittedDraft } from '@inspection/types';
import { getCurrentUser } from '@inspection/utils/auth.util';
import { getStringValueByTitle } from '@inspection/utils/inspectionDetails.util';
import { Button, Flex, Popconfirm, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';

type Props = {
  uniqueCode: string;
  inspection: InspectionData;
  refetch: () => void;
  isBlocked: boolean;
};

const InspectionHeaderActions = ({ uniqueCode, inspection, refetch, isBlocked }: Props) => {
  const [showIntimationDetails, setShowIntimationDetails] = useState(false);
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const toastApi = useToastApi();
  const showMessage = (
    content: string = 'This feature will be available soon!',
    type: 'warning' | 'error' | 'info' | 'loading' | 'success' = 'warning'
  ) => {
    toastApi.open({
      type: type,
      content: content,
      duration: 2,
    });
  };

  const assignToMeButton = (handleAssignToSelf: () => void) => {
    return (
      <Popconfirm
        title={'Confirmation'}
        description={'Assign this inspection to yourself?'}
        okText="Yes"
        cancelText="No"
        onConfirm={handleAssignToSelf}
      >
        <Tooltip placement="bottom" title={'Assign this inspection to yourself'} mouseEnterDelay={2}>
          <Button icon={<UserAddOutlined />} type="default" />
        </Tooltip>
      </Popconfirm>
    );
  };

  const handleAssignToSelf = async () => {
    const response = await inspectionAssignToMe({
      uniqueCode: uniqueCode as string,
    });
    showMessage(response.message, response.success ? 'success' : 'error');
    response.success && refetch();
  };

  const {
    authState: { token },
  } = useAuth();

  useEffect(() => {
    if (token) {
      setCurrentUser(getCurrentUser(token).email);
    }
  }, [token, currentUser, inspection?.acceptedBy]);
  const inspectionStatus =
    getStringValueByTitle('Inspection Status', inspection?.inspectionDetails?.inspectionDetails?.data || []) || '';

  return (
    <>
      <Flex gap={8}>
        {inspection?.acceptedBy == currentUser &&
          !inspection?.submitted &&
          inspectionStatus != InspectionStatus.CDP &&
          !isBlocked && (
            <>
              <CompleteInspection
                uniqueCode={uniqueCode}
                inspectionCode={inspection?.inspectionCode}
                sumAssured={10}
                submittedDraft={inspection?.submittedDraft as TSubmittedDraft}
                refetch={refetch}
              >
                <Tooltip placement="bottom" title={'Mark this inspection as complete'} mouseEnterDelay={2}>
                  <Button icon={<FileDoneOutlined />} type="default" />
                </Tooltip>
              </CompleteInspection>
            </>
          )}
        {!inspection?.submitted && inspection?.acceptedBy === undefined && assignToMeButton(handleAssignToSelf)}
        <Tooltip placement="bottom" title={'View intimation details'} mouseEnterDelay={2}>
          <Button
            icon={<CommentOutlined />}
            type="default"
            onClick={() => {
              setShowIntimationDetails(true);
            }}
          />
        </Tooltip>
      </Flex>
      <ContentOverlay
        title={'Intimation Details'}
        open={showIntimationDetails}
        onCancel={() => setShowIntimationDetails(false)}
      >
        {inspection?.policyDetails?.intimationDetails?.data.length != 0 ? (
          <StatisticGrid data={inspection?.policyDetails?.intimationDetails?.data} columns={4} />
        ) : (
          <Typography.Text>No Data Found</Typography.Text>
        )}
      </ContentOverlay>
    </>
  );
};

export default InspectionHeaderActions;

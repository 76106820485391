import { useAuth } from '@/config/AuthContext/useAuth';
import env from '@/config/env.ts';
import { getInspectionReportRoute } from '@/constants/API_ROUTES';
import CopyPortalLinkButton from '@/features/inspection/components/CopyPortalLinkButton';
import { downloadAllMedia } from '@/features/inspection/services/inspections.service';
import { useToastApi } from '@/hooks/useToastApi';
import {
  CommentOutlined,
  DiffOutlined,
  DownloadOutlined,
  EyeOutlined,
  OrderedListOutlined,
  ProfileOutlined,
  ReloadOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import UploadMediaButton from '@inspection/components/ActionBar/UploadMediaButton';
import ActionsButton from '@inspection/components/ActionsButton';
import { inspectionAssignToMe } from '@inspection/services/inspections.service';
import { InspectionViewModes, TSubmittedDraft } from '@inspection/types';
import { getCurrentUser } from '@inspection/utils/auth.util.ts';
import { useMutation } from '@tanstack/react-query';
import { Badge, Button, Divider, Flex, Popconfirm, Segmented, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

type Props = {
  mode: InspectionViewModes;
  setMode: (mode: InspectionViewModes) => void;
  refetch: () => void;
  assignToMe: { accepted: boolean; uniqueCode: string | undefined; tenantId: string; token: string };
  acceptedBy: string;
  portalLink: string;
  linkToAssessor: boolean;
  uniqueCode: string;
  inspectionCode: string;
  sumAssured: number;
  submittedDraft: TSubmittedDraft;
  isInspectionCompleted: boolean;
  isInspectionBlocked?: boolean;
  isMediaAvailable?: boolean;
  isInspectionInvalid?: boolean;
  showComments?: () => void;
  commentsAvailable?: boolean;
};

const ActionBar = ({
  mode,
  setMode,
  refetch,
  assignToMe,
  acceptedBy,
  portalLink,
  linkToAssessor,
  uniqueCode,
  inspectionCode,
  sumAssured,
  submittedDraft,
  isInspectionCompleted,
  isInspectionBlocked,
  isMediaAvailable,
  isInspectionInvalid = false,
  showComments,
  commentsAvailable,
}: Props) => {
  const toastApi = useToastApi();
  const {
    authState: { token },
  } = useAuth();

  const [currentUser, setCurrentUser] = useState<string>();

  useEffect(() => {
    if (token) {
      setCurrentUser(getCurrentUser(token).email);
    }
  }, [token, currentUser, acceptedBy]);

  const mutation = useMutation({
    mutationFn: () => downloadAllMedia({ uniqueCode }),
    onSuccess: () => showMessage('Bulk media downloaded successfully', 'success'),
    onError: () => showMessage('Bulk media download Failed', 'error'),
  });

  const showMessage = (
    content: string = 'This feature will be available soon!',
    type: 'warning' | 'error' | 'info' | 'loading' | 'success' = 'warning'
  ) => {
    toastApi.open({
      type: type,
      content: content,
      duration: 2,
    });
  };

  return (
    <Flex
      style={{
        width: '100%',
        backgroundColor: 'white',
        padding: '8px 16px',
        borderRadius: '8px',
      }}
      align="center"
      justify="end"
      gap={4}
    >
      <Tooltip placement="bottom" title="Copy customer/assessor portal link" mouseEnterDelay={2}>
        {/* TODO: portal link generation logic should be moved to API */}
        <CopyPortalLinkButton
          portalLinks={{
            generalInspectionPortal: new URL(`${env.VITE_GENERAL_INSPECTION_PORTAL}`) || undefined,
            motorInspectionPortal: linkToAssessor ? undefined : new URL(`${env.VITE_MOTOR_INSPECTION_PORTAL}`),
            mobileAppPortal: linkToAssessor ? new URL(portalLink) : undefined,
          }}
        />
      </Tooltip>
      <a href={getInspectionReportRoute(uniqueCode)}>
        <Button children={'Report'} icon={<ProfileOutlined />} />
      </a>
      <Tooltip placement="bottom" title="Download all photos and videos to your device" mouseEnterDelay={2}>
        <Button
          icon={<DownloadOutlined />}
          type="default"
          loading={mutation.isPending}
          onClick={() => {
            mutation.mutate();
          }}
          disabled={!isMediaAvailable}
        >
          Download Media
        </Button>
      </Tooltip>

      <UploadMediaButton uniqueCode={uniqueCode} refetch={refetch} />

      <Tooltip>
        <Button
          size="middle"
          onClick={showComments}
          icon={
            <Badge size={'small'} dot={commentsAvailable} styles={{ indicator: { fontSize: '8px' } }}>
              <CommentOutlined />
            </Badge>
          }
        >
          Comments
        </Button>
      </Tooltip>

      <Popconfirm
        title={'Confirmation'}
        description={'Assign this inspection to yourself?'}
        okText="Yes"
        cancelText="No"
        onConfirm={async () => {
          const response = await inspectionAssignToMe({
            uniqueCode: assignToMe.uniqueCode as string,
          });
          showMessage(response.message, response.success ? 'success' : 'error');
          response.success && refetch();
        }}
      >
        <Tooltip
          placement="bottom"
          title={assignToMe.accepted ? 'Already Assigned' : 'Assign this inspection to yourself'}
          mouseEnterDelay={2}
        >
          <Button icon={<UserAddOutlined />} disabled={assignToMe.accepted} type="default">
            Assign to me
          </Button>
        </Tooltip>
      </Popconfirm>
      <ActionsButton
        uniqueCode={uniqueCode}
        inspectionCode={inspectionCode}
        sumAssured={sumAssured}
        submittedDraft={submittedDraft}
        refetch={refetch}
        isAuthorizedUser={acceptedBy === currentUser}
        isInspectionBlocked={isInspectionBlocked}
        isInspectionCompleted={isInspectionCompleted}
        isInspectionInvalid={isInspectionInvalid}
      />
      <Divider style={{ backgroundColor: '#d9d9d9' }} type="vertical" />
      <Segmented<InspectionViewModes>
        key={2}
        value={mode}
        onChange={(value) => {
          setMode(value);
        }}
        options={[
          {
            label: 'Preview',
            value: 'preview',
            icon: <EyeOutlined />,
          },
          {
            label: 'Sort',
            value: 'sort',
            icon: <OrderedListOutlined />,
          },
          {
            label: 'Comparison',
            value: 'comparison',
            icon: <DiffOutlined />,
          },
        ]}
      />

      <Divider style={{ backgroundColor: '#d9d9d9' }} type="vertical" />

      <Tooltip title="Reload data" placement="bottom" mouseEnterDelay={2}>
        <Button shape="circle" size="middle" icon={<ReloadOutlined />} onClick={refetch} />
      </Tooltip>
    </Flex>
  );
};

export default ActionBar;

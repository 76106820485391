export const drawArrow = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  color: string,
  thickness: number
) => {
  const angle = Math.atan2(endY - startY, endX - startX);
  const headLength = Math.max(20, thickness * 4);

  // Draw the line
  ctx.beginPath();
  ctx.moveTo(startX, startY);
  ctx.lineTo(endX - headLength * Math.cos(angle), endY - headLength * Math.sin(angle));
  ctx.strokeStyle = color;
  ctx.lineWidth = thickness;
  ctx.stroke();

  // Draw the arrow head
  ctx.beginPath();
  ctx.moveTo(endX, endY); // Tip of the arrow
  ctx.lineTo(endX - headLength * Math.cos(angle - Math.PI / 6), endY - headLength * Math.sin(angle - Math.PI / 6));
  ctx.lineTo(endX - headLength * Math.cos(angle + Math.PI / 6), endY - headLength * Math.sin(angle + Math.PI / 6));
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();

  // Draw the arrow head border
  ctx.strokeStyle = color;
  ctx.lineWidth = thickness;
  ctx.stroke();
};

export const drawRectangle = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  color: string,
  thickness: number
) => {
  const width = endX - startX;
  const height = endY - startY;
  const x = width < 0 ? startX + width : startX;
  const y = height < 0 ? startY + height : startY;

  ctx.beginPath();
  ctx.rect(x, y, Math.abs(width), Math.abs(height));
  ctx.strokeStyle = color;
  ctx.lineWidth = thickness;
  ctx.stroke();
};

export const drawCircle = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  color: string,
  thickness: number
) => {
  const radius = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2));

  ctx.beginPath();
  ctx.arc(startX, startY, radius, 0, 2 * Math.PI);
  ctx.strokeStyle = color;
  ctx.lineWidth = thickness;
  ctx.stroke();
};

export const drawCircleFromEdge = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  color: string,
  thickness: number
) => {
  const width = endX - startX;
  const height = endY - startY;

  const absWidth = Math.abs(width);
  const absHeight = Math.abs(height);

  const centerX = startX + width / 2;
  const centerY = startY + height / 2;

  ctx.beginPath();
  ctx.ellipse(centerX, centerY, absWidth / 2, absHeight / 2, 0, 0, 2 * Math.PI);
  ctx.strokeStyle = color;
  ctx.lineWidth = thickness;
  ctx.stroke();
};
import { useAuth } from '@/config/AuthContext/useAuth';
import { getCurrency } from '@/features/invoices/util';
import { InputNumber, InputNumberProps, Select } from 'antd';

import { CSSProperties } from 'react';

type CurrencyInputProps = InputNumberProps & {
  onCurrencyChange?: (currency: string) => void;
  width?: string;
  defaultCurrency?: string;
  disableCurrencySelect?: boolean;
  currencyOptions?: { value: string; label: string }[];
};

const CurrencySelectInput = ({
  onCurrencyChange = (currency) => {
    return currency;
  },
  width = '100%',
  defaultCurrency,
  currencyOptions = [
    { value: 'USD', label: '$' },
    { value: 'EUR', label: '€' },
    { value: 'GBP', label: '£' },
    { value: 'AUD', label: 'A$' },
    { value: 'INR', label: '₹' },
    { value: 'SGD', label: 'S$' },
    { value: 'LKR', label: 'Rs' },
    { value: 'KHR', label: '៛' },
  ],
  disableCurrencySelect = false,
  ...props
}: CurrencyInputProps) => {
  const styles = useStyles(width);
  const {
    authState: { tenantId },
  } = useAuth();

  const CURRENCY = getCurrency(tenantId);

  defaultCurrency = defaultCurrency || CURRENCY;

  const selectBefore = (
    <Select
      defaultValue={defaultCurrency}
      onChange={onCurrencyChange}
      disabled={props.disabled || disableCurrencySelect}
    >
      {currencyOptions.map((option, index) => (
        <Select.Option key={`${index}`} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
  return (
    <InputNumber
      {...props}
      addonBefore={selectBefore}
      style={styles.currency}
      formatter={(value) => (value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')}
      parser={(value) => (value ? parseFloat(value.replace(/[^\d.-]/g, '')).toFixed(2) : '')}
      onKeyDown={(event) => {
        const target = event.target as HTMLInputElement;
        const { key } = event;
        const value = target.value;

        if (['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(key)) {
          return;
        }

        if ((event.ctrlKey || event.metaKey) && key.toLowerCase() === 'v') {
          return;
        }

        if (key === '.' && value.includes('.')) {
          event.preventDefault();
          return;
        }

        const decimalIndex = value.indexOf('.');
        if (decimalIndex !== -1 && target.selectionStart! > decimalIndex && value.split('.')[1].length >= 2) {
          event.preventDefault();
          return;
        }

        if (!/^\d$/.test(key) && key !== '.') {
          event.preventDefault();
        }
      }}
      precision={2}
    />
  );
};

export default CurrencySelectInput;

const useStyles = (width: string) => {
  return {
    currency: {
      width: width,
    } as CSSProperties,
  };
};

import { queryClient } from '@/config/http/react-query.config';
import {
  deletePOIImage,
  fetchPontOfImpact,
  uploadPointOfImpact,
} from '@/features/inspection/services/inspections.service';
import { TDeleteMedia } from '@/features/inspection/types';
import { useToastApi } from '@/hooks/useToastApi';
import { Annotation, ShapeType } from '@/types/imageAnnotator';
import { useMutation, useQuery } from '@tanstack/react-query';
import { message, Typography } from 'antd';
import { toPng } from 'html-to-image';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import Canvas from './Canvas';
import Toolbar from './Toolbar';

type ImageAnnotatorProps = {
  uniqueCode: string;
  referenceNumber: string;
};

const ImageAnnotator = ({ uniqueCode, referenceNumber }: ImageAnnotatorProps) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [currentAnnotation, setCurrentAnnotation] = useState<Annotation | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [history, setHistory] = useState<Annotation[][]>([[]]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [selectedShape, setSelectedShape] = useState<ShapeType>('arrow');
  const [selectedColor, setSelectedColor] = useState('#4361ee');
  const [thickness, setThickness] = useState(7);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hasAnnotatedImage, setHasAnnotatedImage] = useState<boolean>(false);

  const toastApi = useToastApi();

  const resetCanvas = () => {
    setAnnotations([]);
    setHistory([[]]);
    setHistoryIndex(0);
  };

  const styles: Styles = useStyles();
  const { data: pointOfImpactImage } = useQuery({
    queryKey: ['point-of-impact-template', referenceNumber],
    queryFn: () => fetchPontOfImpact({ referenceNumber }),
    staleTime: 60 * 60 * 1000,
    enabled: !!referenceNumber,
  });

  useEffect(() => {
    const defaultImage = new Image();
    defaultImage.crossOrigin = 'anonymous';
    defaultImage.src = pointOfImpactImage?.[0]?.pointOfImpact || '/images/poi-template.jpg';
    setHasAnnotatedImage(pointOfImpactImage?.[0]?.pointOfImpact ? true : false);
    defaultImage.onload = () => {
      setImage(defaultImage);
    };
  }, [pointOfImpactImage]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          setImage(img);
          resetCanvas();
        };
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const addToHistory = useCallback(
    (newAnnotations: Annotation[]) => {
      setHistory((prev) => [...prev.slice(0, historyIndex + 1), [...newAnnotations]]);
      setHistoryIndex((prev) => prev + 1);
    },
    [historyIndex]
  );

  const getStraightLine = (startX: number, startY: number, endX: number, endY: number) => {
    const dx = endX - startX;
    const dy = endY - startY;
    const angle = Math.atan2(dy, dx);
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Convert angle to degrees and round to nearest 45°
    let snapAngle = Math.round((angle * (180 / Math.PI)) / 45) * 45;
    snapAngle = snapAngle * (Math.PI / 180);

    return {
      endX: startX + distance * Math.cos(snapAngle),
      endY: startY + distance * Math.sin(snapAngle),
    };
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!image) return;

    const newAnnotation: Annotation = {
      id: Date.now().toString(),
      type: selectedShape,
      startX: e.clientX,
      startY: e.clientY,
      endX: e.clientX,
      endY: e.clientY,
      color: selectedColor,
      thickness,
    };

    setCurrentAnnotation(newAnnotation);
    setIsDrawing(true);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDrawing || !currentAnnotation) return;

    let endX = e.clientX;
    let endY = e.clientY;

    if (e.shiftKey) {
      const straightLine = getStraightLine(currentAnnotation.startX, currentAnnotation.startY, endX, endY);
      endX = straightLine.endX;
      endY = straightLine.endY;
    }

    setCurrentAnnotation({
      ...currentAnnotation,
      endX,
      endY,
    });
  };

  const handleMouseUp = () => {
    if (!isDrawing || !currentAnnotation) return;

    const newAnnotations = [...annotations, currentAnnotation];
    setAnnotations(newAnnotations);
    addToHistory(newAnnotations);
    setCurrentAnnotation(null);
    setIsDrawing(false);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      const newIndex = historyIndex - 1;
      setHistoryIndex(newIndex);
      setAnnotations(history[newIndex]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      const newIndex = historyIndex + 1;
      setHistoryIndex(newIndex);
      setAnnotations(history[newIndex]);
    }
  };

  const handleClear = () => {
    if (!annotations.length) return;
    resetCanvas();
  };

  const handleResetAnnotations = () => {
    const defaultImage = new Image();
    defaultImage.crossOrigin = 'anonymous';
    defaultImage.src = '/images/poi-template.jpg';
    defaultImage.onload = () => {
      setImage(defaultImage);
    };
    deleteAnnotationMutation.mutate({
      uniqueCode: pointOfImpactImage?.[0]?.inspectionIdOfThePOI ?? '',
      docId: pointOfImpactImage?.[0]?.docId || '',
    });
    resetCanvas();
  };

  const handleRemoveImage = () => {
    setImage(null);
    resetCanvas();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const pointOfImpactUploadMutation = useMutation({
    mutationFn: (file: File) => uploadPointOfImpact({ uniqueCode, files: [file] }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['point-of-impact-template', referenceNumber] });
      toastApi.success('Point of impact uploaded successfully');

      // TODO: Fix this, this should be handled after the query invalidation is success
      setTimeout(() => {
        resetCanvas();
      }, 3000);
    },
    onError: () => {
      toastApi.error('Failed to upload point of impact');
    },
  });

  const deleteAnnotationMutation = useMutation({
    mutationFn: ({ uniqueCode, docId }: TDeleteMedia) => deletePOIImage({ uniqueCode, docId }),
    onSuccess: (data) => {
      toastApi.success(data?.message || 'Annotation deleted successfully');
      queryClient.invalidateQueries({ queryKey: ['point-of-impact-template', referenceNumber] });
    },
    onError: (data) => {
      toastApi.error(data?.message || 'Failed to delete annotation');
    },
  });

  const handleDownload = async () => {
    if (!image) return;

    try {
      const canvas = document.querySelector('canvas');
      if (!canvas) return;

      const dataUrl = await toPng(canvas);

      const link = document.createElement('a');
      link.download = 'annotated-image.png';
      link.href = dataUrl;
      link.click();
    } catch (err) {
      message.error('Failed to download image');
    }
  };

  const handleUploadClick = () => {
    if (!image) return;

    try {
      const canvas = document.querySelector('canvas');
      if (!canvas) return;

      canvas.toBlob(async (blob) => {
        const file = new File([blob as Blob], 'point-of-impact.jpg', { type: 'image/jpg' });
        pointOfImpactUploadMutation.mutate(file);
      }, 'image/jpg');
    } catch (err) {
      message.error('Failed to upload image');
    }
  };

  return (
    <div style={styles.container}>
      <input
        ref={fileInputRef}
        type="file"
        id="imageUpload"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageUpload}
      />
      <Toolbar
        isLoading={pointOfImpactUploadMutation.isPending}
        onUndo={handleUndo}
        onRedo={handleRedo}
        onClear={handleClear}
        onRemoveImage={handleRemoveImage}
        onDownload={handleDownload}
        onUpload={handleUploadClick}
        onResetAnnotation={handleResetAnnotations}
        selectedShape={selectedShape}
        selectedColor={selectedColor}
        thickness={thickness}
        onShapeChange={setSelectedShape}
        onColorChange={setSelectedColor}
        onThicknessChange={setThickness}
        canUndo={historyIndex > 0}
        canRedo={historyIndex < history.length - 1}
        hasImage={!!image}
        hasAnnotatedImage={hasAnnotatedImage}
        hasAnnotations={annotations.length > 0}
      />

      {image ? (
        <Canvas
          image={image}
          annotations={annotations}
          isDrawing={isDrawing}
          currentAnnotation={currentAnnotation}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
      ) : (
        <Typography.Text>Loading the template</Typography.Text>
      )}
    </div>
  );
};

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '300px',
      height: '100%',
    } as CSSProperties,
    toolbar: {
      display: 'flex',
      gap: '8px',
      padding: '16px',
      borderBottom: '1px solid #e5e7eb',
    } as CSSProperties,
    canvasContainer: {
      position: 'relative',
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      minHeight: '500px',
    } as CSSProperties,
    canvas: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      cursor: 'crosshair',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    } as CSSProperties,
  };
};

export default ImageAnnotator;

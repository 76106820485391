import { Button, Flex, Typography } from 'antd';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

function Home() {
  const styles = useStyles();

  return (
    <Flex align="center" justify="center" style={styles.placeholder}>
      <Typography.Title level={1}>DigiEye Console (BETA)</Typography.Title>
      <Link to={`inspection`}>
        <Button type="primary">Inspection List</Button>
      </Link>
    </Flex>
  );
}

export default Home;

const useStyles = () => ({
  placeholder: {
    height: 'calc(100vh - 60px)',
    opacity: 0.5,
    width: '100%',
    flexDirection: 'column',
  } as CSSProperties,
});

import { useAuth } from '@/config/AuthContext/useAuth';
import { getInspectionInvoiceRoute } from '@/constants/API_ROUTES';
import {
  createInvoice as createInvoiceService,
  getInvoiceTypes,
  getInvoicesSummery,
} from '@/features/invoices/services/invoices.service';
import { useToastApi } from '@/hooks/useToastApi';
import '@/index.css';
import { PlusOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons';
import { formatCurrency } from '@invoices/util';
import { TCreateInvoiceForm, TErrorResponse } from '@invoices/util/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Col, Collapse, Divider, Flex, Form, Input, Popover, Row, Select, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { CSSProperties, useEffect, useState } from 'react';
dayjs.extend(RelativeTime);

type Props = {
  uniqueCode?: string;
  referenceNumber?: string;
  vehicleNumber?: string;
  containerStyles?: CSSProperties;
};

function Invoices({ uniqueCode, referenceNumber, vehicleNumber, containerStyles }: Props) {
  const {
    authState: { tenantId },
  } = useAuth();
  const toast = useToastApi();
  const {
    data: response,
    isFetching: isInvoicesLoading,
    refetch: refetchInvoices,
  } = useQuery({
    queryKey: ['inspection-invoices-summary', uniqueCode],
    queryFn: () => getInvoicesSummery({ uniqueCode: uniqueCode as string }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
    enabled: !!uniqueCode,
  });

  const queryClient = useQueryClient();

  const { mutate: createInvoice } = useMutation({
    mutationFn: createInvoiceService,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ['inspection-invoices-summary', uniqueCode] });
      queryClient.invalidateQueries({ queryKey: ['inspection-invoices', uniqueCode] });
      toast.success({
        content: res.message || 'Invoice added successfully!',
        duration: 2,
      });
    },
    onError: (res: TErrorResponse) => {
      const message = res?.response?.data?.message?.includes('invoiceNumber')
        ? 'Invoice number already exists'
        : res?.message || 'Failed to add, please try again';

      toast.error({
        content: message,
        duration: 2,
      });
    },
  });

  useEffect(() => {
    refetchInvoices();
  }, [refetchInvoices]);

  const styles = useStyles();

  return (
    <div style={{ ...styles.container, ...containerStyles }}>
      <Collapse
        size="small"
        defaultActiveKey={['invoices']}
        style={{ backgroundColor: 'rgba(240, 245, 255, 0.5)' }}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <span style={styles.expandIcon}>
            <RightOutlined rotate={isActive ? 90 : 0} />
          </span>
        )}
        items={[
          {
            key: 'invoices',
            label: <Typography.Link style={styles.label}>Invoices & Estimations</Typography.Link>,
            children: (
              <Spin spinning={isInvoicesLoading}>
                {response?.invoices && response?.invoices.length > 0 ? (
                  <div style={styles.scrollContainer} className="scrollContainer">
                    {response.invoices.map(
                      ({ invoiceId, invoiceName, invoiceTypeCode, grandTotal, invoiceTypeName }) => (
                        <div key={invoiceId} style={styles.invoiceContainer}>
                          <a
                            key={invoiceId}
                            href={getInspectionInvoiceRoute(uniqueCode || '', invoiceId, invoiceTypeCode)}
                          >
                            <Flex vertical>
                              <Typography.Text style={styles.invoiceNameTxt}>{invoiceName || ''}</Typography.Text>
                              <Typography.Text style={styles.invoiceTypeNameTxt}>
                                {invoiceTypeName || ''}
                              </Typography.Text>
                            </Flex>
                            <Divider style={styles.divider} />
                            <Space style={styles.invoiceContainerBottomSection}>
                              <Typography.Text style={styles.invoiceIdTxt}>
                                {invoiceId ? `#${invoiceId}` : ''}
                              </Typography.Text>
                              <Typography.Text style={styles.grandTotalTxt}>
                                {formatCurrency(grandTotal || 0, tenantId)}
                              </Typography.Text>
                            </Space>
                          </a>
                        </div>
                      )
                    )}
                  </div>
                ) : null}
                <AddNewInvoice
                  onCreate={(invoiceName, invoiceTypeCode, invoiceNumber) =>
                    createInvoice({
                      invoiceTypeCode: invoiceTypeCode,
                      invoiceName: invoiceName,
                      invoiceNumber: invoiceNumber,
                      inspectionId: uniqueCode as string,
                      referenceNumber: referenceNumber as string,
                      vehicleNumber: vehicleNumber as string,
                    })
                  }
                />
                {response?.updatedAt && (
                  <div style={styles.updatedAtSection}>
                    <Button
                      size="small"
                      type="text"
                      icon={<ReloadOutlined />}
                      onClick={() => refetchInvoices()}
                      loading={isInvoicesLoading}
                    />
                    <Typography.Text
                      style={styles.updatedAtTxt}
                    >{`Last Update ${dayjs(response.updatedAt).from(dayjs())}`}</Typography.Text>
                  </div>
                )}
              </Spin>
            ),
          },
        ]}
      />
    </div>
  );
}

const AddNewInvoice = ({
  onCreate,
}: {
  onCreate: (invoiceName: string, invoiceTypeCode: string, invoiceNumber: string) => void;
}) => {
  const [form] = Form.useForm();

  const [showCreateInvoicePopover, setShowCreateInvoicePopover] = useState(false);
  const onFinish = (values: TCreateInvoiceForm) => {
    onCreate(values.invoiceName, values.invoiceTypeCode, values.invoiceNumber);
    form.resetFields();
    setShowCreateInvoicePopover(false);
  };

  const { data: invoiceTypes } = useQuery({
    queryKey: ['invoice-types'],
    queryFn: () => getInvoiceTypes(),
  });
  return (
    <>
      <Flex>
        <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => setShowCreateInvoicePopover(true)}
          children={'Add Invoice'}
          block
        />
      </Flex>
      <Popover
        onOpenChange={setShowCreateInvoicePopover}
        open={showCreateInvoicePopover}
        title="Add Invoice"
        trigger={['click']}
        placement="topRight"
        content={
          <Form
            form={form}
            style={{ width: '100%' }}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              invoiceTypeCode: 'INVOICE',
              invoiceNumber: '',
              invoiceName: null,
            }}
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Invoice Type"
                  name="invoiceTypeCode"
                  rules={[{ required: true, message: 'Please select a type!' }]}
                >
                  <Select popupMatchSelectWidth={200}>
                    {invoiceTypes?.map(({ invoiceTypeCode, invoiceTypeName }) => (
                      <Select.Option key={invoiceTypeCode} value={invoiceTypeCode}>
                        {invoiceTypeName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Invoice no"
                  name="invoiceNumber"
                  // rules={[{ required: true, message: 'Please enter the invoice number!' }]}
                >
                  <Input placeholder="Invoice No" prefix={'#'} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Invoice Name"
              name="invoiceName"
              rules={[{ required: true, message: 'Please enter the invoice name!' }]}
            >
              <Input placeholder="Invoice Name" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Create an invoice
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </>
  );
};

export default Invoices;

const useStyles = () => ({
  container: {} as CSSProperties,
  label: { fontWeight: 600, color: '#0035cc' } as CSSProperties,
  expandIcon: {
    color: '#0035cc',
    fontSize: '10px',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    display: 'flex',
    borderRadius: '100%',
    backgroundColor: '#fff',
    justifyContent: 'center',
    border: '1px solid rgba(22, 119, 255, 0.15)',
  } as CSSProperties,
  collapse: {
    width: '100',
    backgroundColor: 'rgba(240, 245, 255, 0.5)',
  } as CSSProperties,
  button: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as CSSProperties,
  scrollContainer: {
    padding: '4px 8px 0 0',
    maxHeight: '250px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: '#1677FF',
    marginBottom: '12px',
  } as CSSProperties,
  invoiceContainer: {
    borderRadius: '8px',
    padding: '8px',
    marginBottom: '8px',
    backgroundColor: '#F6F7F9',
  } as CSSProperties,
  invoiceNameTxt: {
    fontSize: '14px',
    fontWeight: 600,
  } as CSSProperties,
  invoiceTypeNameTxt: {
    fontSize: '12px',
    color: '#64748B',
  } as CSSProperties,
  divider: {
    margin: '8px 0',
  } as CSSProperties,
  invoiceContainerBottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as CSSProperties,
  invoiceIdTxt: {
    fontSize: '14px',
    color: '#B1BBC8',
  } as CSSProperties,
  grandTotalTxt: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#1677FF',
  } as CSSProperties,
  updatedAtSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '4px 0',
    padding: '0 16px',
    fontSize: '12px',
    color: '#8c8c8c',
  } as CSSProperties,
  updatedAtTxt: {
    color: '#8695AA',
  } as CSSProperties,
});

import env from '@/config/env.ts';

export const API_ROUTES = {
  GET_OLD_INSPECTION_LIST: (tenantId: string, vehicleNumber: string) =>
    `${env.VITE_API_URL}${tenantId}/external/v1/get_inspection_list?search=${vehicleNumber}&limit=10&pageNo=1`,
} as const;

export const API_ROUTES_UNENCRYPTED = {
  INSPECTION_ASSIGN_TO_ME: `/dashboard/tracker/v1/accept-inspection`,
  GET_All_MEDIA: `/dashboard/tracker/v1/downloadAllMedia`,
  UPLOAD_DOCUMENTS: `/dashboard/tracker/v1/upload-files`,
  COMPLETE_INSPECTION: `/dashboard/tracker/v1/submit`,
  MARK_AS_BLOCKED: `/dashboard/tracker/v1/mark-as-blocked`,
  MARK_AS_RESOLVED: `/dashboard/tracker/v1/mark-as-resolved`,
  ADD_COMMENT: `/dashboard/tracker/v1/add-comment`,
  // TODO:combine these two to one endpoint
  MARK_AS_INVALID: `/dashboard/tracker/v1/mark-as-invalid`,
  MARK_AS_VALID: `/dashboard/tracker/v1/mark-as-valid`,
  DELETE_MEDIA: `/dashboard/tracker/v1/delete-media`,
  DELETE_POI: `/dashboard/tracker/v1/delete-media?pointOfImpact=true`,
  REPORT_GENERATE: `/dashboard/tracker/v1/inspection_list_report`,
} as const;

// TODO: move the attributes in here to API_ROUTES after testing
export const API_ROUTES_UPDATED = {
  GET_INSPECTION_INFO: `/external/v1/get_inspection`,
  INSPECTION_ASSIGN_TO_ME: `/dashboard/tracker/v1/accept-inspection`,
  GET_INSPECTION_LIST: `/v2/inspections?`,
  GET_INSPECTION_ACCEPTED_USER: `v2/inspections/getInspectionAcceptedBy`,
  GET_INSPECTION_EVIDENCE: `/dashboard/tracker/v1/evidence`,
  GET_INSPECTION_COUNT: `/v2/inspections/count?`,
  GET_ASSESSOR_LIST: `/v2/users/assessors`,
  GET_ROLES_LIST: `/v2/users/roles`,
  GET_INSPECTION_TYPES: `v2/inspections/types`,
  GET_ASSIGNED_TO_LIST: `/v2/users/assignedToList`,
  CREATE_INSPECTION: `/v2/inspections/request`,
  CREATE_INCIDENT_DETAILS: `/v2/inspections/incidentDetails`,
  REPORT_TEMPLATES: `/v2/incident-details/templates`,
  REPORT_TEMPLATE_FIELDS: `/v2/incident-details/fields`,
  REPORT_TEMPLATE_FIELDS_VALUES: `/v2/incident-details/values`,
  GET_INSPECTION_SUMMARY: `/v2/inspections/summary`,
  GET_EVENT_DATA: `/v2/inspections/event`,
  INSPECTION_INVOICES: `/v2/invoices`,
  INSPECTION_TAX_INVOICES: `/v2/inspections/incidentDetails?referenceNumber=`,
  GENERATE_INSPECTION_PDF: `/v2/inspections/generateInspectionPDF?referenceNumber=`,
  GET_INSPECTION_INVOICES_SUMMERY: `/v2/invoices/summary`,
  SAVE_SORTED_MEDIA: `/v2/inspections/mediaSort`,
  INVOICE_TYPE_DETAILS: `/v2/invoice-types`,
  EVENT_STATUSES: `/v2/inspections/event-status/`,
  GET_CURRENT_EVENT_STATUS: `/v2/inspections/event-status?inspectionId=`,
} as const;

export const CLIENT_ROUTES = {
  HOME: '/',
  INSPECTION_PREVIEW: (uniqueCode: string) => `/inspection/${uniqueCode}`,
  INSPECTION_REPORT: (uniqueCode: string) => `/inspection/report/${uniqueCode}`,
  INSPECTION_INVOICE: (uniqueCode: string, invoiceId: number, invoiceTypeCode: string) =>
    `/inspection/invoice/${uniqueCode}?invoiceId=${invoiceId}&invoiceTypeCode=${invoiceTypeCode}`,
  INSPECTION_LIST: (tenantId: string, token: string) => `/inspection/?tenantId=${tenantId}&token=${token}`,
} as const;

export function getInspectionRoute(uniqueCode: string) {
  return CLIENT_ROUTES.INSPECTION_PREVIEW(uniqueCode);
}

export function getInspectionReportRoute(uniqueCode: string) {
  return CLIENT_ROUTES.INSPECTION_REPORT(uniqueCode);
}

export function getInspectionInvoiceRoute(inspectionId: string, invoiceId: number, invoiceTypeCode: string) {
  return CLIENT_ROUTES.INSPECTION_INVOICE(inspectionId, invoiceId, invoiceTypeCode);
}

import { queryClient } from '@/config/http/react-query.config';
import InvoiceView from '@/features/invoices/components/InvoiceView';
import {
  deleteLineItem,
  getInvoice,
  getInvoiceTypeDetails,
  updateInvoice,
} from '@/features/invoices/services/invoices.service';
import { useToastApi } from '@/hooks/useToastApi';
import { SettingOutlined } from '@ant-design/icons';
import CustomFooterForm from '@invoices/components/CustomFooterForm';
import CustomHeaderForm from '@invoices/components/CustomHeaderForm';
import CustomInvoiceSegments from '@invoices/components/CustomInvoiceSegments';
import InvoiceForm from '@invoices/components/InvoiceForm';
import {
  TCustomFooterForm,
  TCustomHeaderForm,
  TCustomInvoiceSegments,
  TInvoiceResponse,
  TLineItem,
} from '@invoices/util/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Breadcrumb, Card, Flex, Tabs, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import isEmpty from 'lodash/isEmpty';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const InspectionInvoiceLayout = () => {
  const location = useLocation();
  const styles = useStyles();
  const { uniqueCode } = useParams<{ uniqueCode: string }>();
  const toast = useToastApi();
  const [itemToEdit, setItemToEdit] = useState<TLineItem | undefined>(undefined);
  const searchParams = new URLSearchParams(location.search);
  const invoiceId = searchParams.get('invoiceId') ? parseInt(searchParams.get('invoiceId') as string) : undefined;
  const invoiceTypeCode = searchParams.get('invoiceTypeCode') || undefined;
  const componentRef = useRef<HTMLDivElement>(null);
  const [segmentedValue, setSegmentedValue] = useState<TCustomInvoiceSegments>('Line Items');
  const [headerData, setHeaderData] = useState<TCustomHeaderForm>([] as TCustomHeaderForm);
  const [footerData, setFooterData] = useState<TCustomFooterForm>([] as TCustomFooterForm);
  const [overallDiscount, setOverallDiscount] = useState<number>(0);

  const { data: invoice } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: async () => {
      const data = await getInvoice({ invoiceId });
      setOverallDiscount(data?.lineItems[0].discount || 0);
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
  });

  const { data: invoiceTypeDetails } = useQuery({
    queryKey: ['invoiceTypeDetails', invoiceTypeCode],
    queryFn: () => getInvoiceTypeDetails({ invoiceTypeCode: invoiceTypeCode }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    enabled: !!invoiceTypeCode,
  });

  const { mutate: updateInvoiceMutation, isPending: updateInvoiceIsPending } = useMutation({
    mutationFn: (data: TCustomFooterForm | TCustomHeaderForm) => updateInvoice(invoiceId, data),
    onSuccess: (response) => {
      toast.success(response?.message || 'Invoice updated successfully');
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] });
    },
    onError: (error) => {
      toast.error(error?.message || 'Failed to update invoice');
    },
  });

  useEffect(() => {
    if (!isEmpty(headerData) || !isEmpty(footerData)) {
      updateInvoiceMutation({
        ...headerData,
        ...footerData,
      });
    }
  }, [headerData, footerData, updateInvoiceMutation]);

  const onLineItemsDelete = async (lineItems: TLineItem[]) => {
    await Promise.all(
      lineItems.map(async (lineItem) => {
        await deleteLineItem(lineItem.invoiceId, lineItem.id);
      })
    );
    setItemToEdit(undefined);
    await queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] });
  };

  const sections = useMemo(
    () =>
      Array.from(new Set(invoice?.lineItems?.map((item) => item.sectionCode))).map((section) => ({
        value: section,
        label: section,
      })),
    [invoice?.lineItems]
  );

  return (
    <div style={styles.container}>
      <Flex justify="space-between" align="center" style={styles.buttonBar} gap={8}>
        <div>
          <Breadcrumb
            separator="/"
            items={[
              {
                title: 'Inspection Overview',
                href: `/inspection/`,
              },
              { title: 'Inspection', href: `/inspection/${uniqueCode}` },
              { title: `Invoice #${invoiceId}` },
            ]}
            style={styles.breadCrumb}
          />
          <Title level={4} style={styles.mainTitle}>
            Inspection Invoice
          </Title>
        </div>
      </Flex>
      <Flex justify="space-between" gap={8}>
        {/* Invoice container */}
        <Card style={styles.invoiceContainerCard}>
          <div ref={componentRef}>
            <InvoiceView
              invoiceTypeDetails={invoiceTypeDetails}
              invoice={invoice}
              onDeleteLineItems={onLineItemsDelete}
              onSelectToEdit={(value) => setItemToEdit(value)}
              itemToEdit={itemToEdit}
              setOverallDiscount={(value) => setOverallDiscount(value)}
            />
          </div>
        </Card>
        <Card style={styles.invoiceConfigCard}>
          {/* Side pane header */}
          <Flex gap={8} align="center">
            <Flex justify="center" align="center" style={styles.invoiceConfigurationIconContainer}>
              <SettingOutlined />
            </Flex>
            <Flex vertical>
              <Title level={5} style={styles.marginZero}>
                Invoice Configuration
              </Title>
              <Typography.Text style={styles.marginZero}>Templates and History Review</Typography.Text>
            </Flex>
          </Flex>

          {/* Tabs */}
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: 'Add/Edit Items',
                children: (
                  <Flex vertical align="center" gap={8}>
                    <CustomInvoiceSegments
                      hidden={!invoice?.lineItems}
                      segment={segmentedValue}
                      setSegments={(value) => setSegmentedValue(value)}
                    />
                    {segmentedValue === 'Header' ? (
                      <CustomHeaderForm
                        loading={updateInvoiceIsPending}
                        invoice={invoice || ({} as TInvoiceResponse)}
                        setHeaderData={(value) => setHeaderData(value)}
                      />
                    ) : segmentedValue === 'Footer' ? (
                      <CustomFooterForm
                        loading={updateInvoiceIsPending}
                        setFooterData={(value) => setFooterData(value)}
                        customerNote={invoice?.customerNote || ''}
                        terms={invoice?.terms || ''}
                        dueDate={invoice?.dueDate || ''}
                      />
                    ) : (
                      <InvoiceForm
                        applyDiscountToTotal={invoiceTypeDetails?.applyDiscountToTotal || false}
                        fields={invoiceTypeDetails?.tableColumns || []}
                        sections={sections}
                        itemToEdit={itemToEdit}
                        onUpdate={() => setItemToEdit(undefined)}
                        discount={overallDiscount}
                      />
                    )}
                  </Flex>
                ),
              },
              //TODO: Implement these features later
              // {
              //   key: '2',
              //   label: 'Export Templates',
              //   children: 'Invoice Export Templates',
              // },
              // {
              //   key: '3',
              //   label: 'Edit History',
              //   children: 'Invoice History',
              // },
            ]}
            style={styles.tabs}
          />
        </Card>
      </Flex>
    </div>
  );
};

export default InspectionInvoiceLayout;

const useStyles = () => {
  return {
    container: {
      padding: '0px 16px',
      backgroundColor: '#F5F4F7',
    } as CSSProperties,
    mainTitle: {
      margin: 0,
      fontWeight: '500',
    } as CSSProperties,
    buttonBar: { marginTop: 16 } as CSSProperties,
    breadCrumb: { fontSize: '12px' } as CSSProperties,
    invoiceContainerCard: {
      width: '100%',
      marginTop: 16,
    } as CSSProperties,
    invoiceConfigCard: {
      width: '500px',
      marginTop: 16,
    } as CSSProperties,
    marginZero: {
      margin: 0,
      lineHeight: 1.2,
    } as CSSProperties,
    invoiceConfigurationIconContainer: {
      borderRadius: '50%',
      border: '1px solid #D9D9D9',
      width: '32px',
      height: '32px',
    } as CSSProperties,
    tabs: {
      marginTop: 16,
    } as CSSProperties,
  };
};

export const getCurrentUser = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = JSON.parse(
      decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
          .join('')
      )
    );
    const { email, name, role } = jsonPayload;
    return { email, name, role };
  } catch (error) {
    throw new Error('Error while decoding token');
  }
};

export const getInitials = (name: string): string => {
  const nameParts = name.split(' ');
  // If there is only one name part, return the first two characters
  if (nameParts.length === 1) return nameParts[0].slice(0, 2).toUpperCase();

  const initials = nameParts
    .filter((part) => part.length > 0)
    .map((part) => part.charAt(0).toUpperCase())
    .join('');
  return initials;
};

const colorPalette = [
  '#FFB900', // Yellow
  '#D83B01', // Orange
  '#B50E0E', // Bright red
  '#E81123', // Red
  '#B4009E', // Dark magenta
  '#5C2D91', // Light purple
  '#0078D7', // Blue
  '#00B4FF', // Light blue
  '#008272', // Teal
  '#107C10', // Dark green
];

export const getColorForName = (name: string): string => {
  const initial = name.charAt(0).toUpperCase();
  const charCode = initial.charCodeAt(0);
  const colorIndex = charCode % colorPalette.length;
  return colorPalette[colorIndex];
};
